// import LoginForm from "../../components/LoginForm";
// import { Container, Header } from './styles';

// import LogoSaber2 from '../../assets/images/landing-page/Logo-Saber2.png'
// import LogoSaber2 from '../../assets/images/landing-page/LOGO.png'
// import LogoSaber2 from '../../assets/images/2024/banner_saber.svg'

// import LogoCofeci from '../../assets/images/landing-page/logo_cofeci2x.png'
// import LogoCofeci from '../../assets/images/landing-page/cofeci@2x.png'
import LogoCofeci from '../../assets/images/landing-page/logo_cofeci.png'
import LogoSebrae from '../../assets/images/landing-page/logo_sebrae2x.png'
import LogoCimi from '../../assets/images/landing-page/logo_cimi_original_branco.png'

import LogoHomer2 from '../../assets/images/landing-page/logo_homer2x.png'
// import LogoIbrep from '../../assets/images/landing-page/logoIbrep.png'
import LogoIGlobal from '../../assets/images/landing-page/logo_iglobal2x.png'
import FormGroup from '../../components/FormGroup'
import useErrors from '../../hooks/useErrors'
import LeadsService from '../../services/LeadsService'
import StatesService from '../../services/StatesService'
import CitiesService from '../../services/CitiesService'
import Config from '../../config/environments/local';
import {
  localGet,
  // localRemove
} from "../../lib/session";

import isEmailValid from '../../utils/isEmailValid'

import LogoCreciAc from '../../assets/images/landing-page/creci/creci_ac.png'
import LogoCreciAl from '../../assets/images/landing-page/creci/creci_al.png'
import LogoCreciAm from '../../assets/images/landing-page/creci/creci_am.png'
import LogoCreciAP from '../../assets/images/landing-page/creci/creci_ap.png'
import LogoCreciBa from '../../assets/images/landing-page/creci/creci_ba.png'
import LogoCreciCe from '../../assets/images/landing-page/creci/creci_ce.png'
import LogoCreciDf from '../../assets/images/landing-page/creci/creci_df.png'
import LogoCreciEs from '../../assets/images/landing-page/creci/creci_es.png'
import LogoCreciGo from '../../assets/images/landing-page/creci/creci_go.png'
import LogoCreciMa from '../../assets/images/landing-page/creci/creci_ma.png'
import LogoCreciMg from '../../assets/images/landing-page/creci/creci_mg.png'
import LogoCreciMs from '../../assets/images/landing-page/creci/creci_ms.png'
import LogoCreciMt from '../../assets/images/landing-page/creci/creci_mt.png'
import LogoCreciPa from '../../assets/images/landing-page/creci/creci_pa.png'
import LogoCreciPb from '../../assets/images/landing-page/creci/creci_pb.png'
import LogoCreciPe from '../../assets/images/landing-page/creci/creci_pe.png'
import LogoCreciPi from '../../assets/images/landing-page/creci/creci_pi.png'
import LogoCreciPr from '../../assets/images/landing-page/creci/creci_pr.png'
import LogoCreciRj from '../../assets/images/landing-page/creci/creci_rj.png'
import LogoCreciRn from '../../assets/images/landing-page/creci/creci_rn.png'
import LogoCreciRo from '../../assets/images/landing-page/creci/creci_ro.png'
import LogoCreciRr from '../../assets/images/landing-page/creci/creci_rr.png'
import LogoCreciRs from '../../assets/images/landing-page/creci/creci_rs.png'
import LogoCreciSc from '../../assets/images/landing-page/creci/creci_sc.png'
import LogoCreciSe from '../../assets/images/landing-page/creci/creci_se.png'
import LogoCreciSp from '../../assets/images/landing-page/creci/creci_sp.png'
import LogoCreciTo from '../../assets/images/landing-page/creci/creci_to.png'

import Loader from '../../components/Loader'
import Modal from '../../components/Modal'
import ModalEmail from '../../components/ModalEmail'

// import Teodoro from '../../assets/images/landing-page/palestrantes/2024/joao-teodoro.svg'
import Palestrante1 from '../../assets/images/2024/palestrantes/palestrante1.png'
import Palestrante2 from '../../assets/images/2024/palestrantes/palestrante2.png'
import Palestrante3 from '../../assets/images/2024/palestrantes/palestrante3.png'
// import DepoimentoCircle from '../../assets/images/2024/circle_frame_depoimento.svg'
import Aspas from '../../assets/images/2024/aspas.svg'
import HuggingPeople from '../../assets/images/2024/home_huggingpeople.svg'
// import Sabrina from '../../assets/images/landing-page/palestrantes/sabina@2x.png'
// import Kepler from '../../assets/images/landing-page/palestrantes/joao_kepler@2x.png'
// import Walter from '../../assets/images/landing-page/palestrantes/walter@2x.png'
import Clock from '../../assets/icons/clock.svg'

import Slider from "react-slick";
// import { useCountdown } from '../../components/useCountdown'
// import CountdownTimer from '../components/CountdownTimer';
// import CountdownTimer from '../../components/CountdownTimer'

import {
  useState,
  useEffect,
  useCallback,
} from "react";
import UsersService from '../../services/UsersService'

export default function Home() {

  const {
    errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalSucessOpen, setIsModalSucessOpen] = useState(false);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [realtorType, setRealtorType] = useState('');
  const [creci, setCreci] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmeEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [confirmPassword, setConfirmPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [cnpj, setCnpj] = useState('');

  const [activeTabPane, setActiveTabPane] = useState(3);

  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [userIsLogedIn, setUserIsLogedIn] = useState(false);
  const [isDownloadButton, setIsDownloadButton] = useState(false);


  if (isFirstLoad && sessionObject) {
    setIsFirstLoad(false);
    setUserIsLogedIn(true);
  }

  const loadCitiesByStateId = useCallback(async () => {
    try {
      // setIsLoading(true);
      const citiesList = await new CitiesService(Config.API_BASE_URL).getByStateId(state);
      setCities(citiesList.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      // setIsLoading(false);
    }
  }, [state]);

  useEffect(() => {
    async function loadStates() {
      try {
        // setIsLoading(true);
        const statesList = await new StatesService(Config.API_BASE_URL).getAll();
        setStates(statesList.Result);
      } catch (error) {
        console.log('error ', error);
      } finally {
        // setIsLoading(false);
      }
    }

    async function GetMustShowDownloadMaterialButton() {
      if (!isFirstLoad)
        return;

      try {
        // setIsLoading(true);
        const mustshowdownloadmaterialbuttonResponse = await new LeadsService(Config.API_BASE_URL).GetMustShowDownloadMaterialButton();
        setIsDownloadButton(mustshowdownloadmaterialbuttonResponse.Result);
      } catch (error) {
        console.log('error ', error);
      } finally {
        // setIsLoading(false);
      }
    }

    GetMustShowDownloadMaterialButton();

    loadStates();
    loadCitiesByStateId();
  }, [isFirstLoad, loadCitiesByStateId]);

  const isFormValid = (errors.length === 0);

  // const isFormValid = function () {
  //   let formIsValid = (errors.length === 0) ?? false;
  //   return formIsValid;
  // }

  var slickSettings = {
    autoPlay: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
  };

  var slickSettings2 = {
    autoPlay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  async function AddLeadWatchedContent(contentId, urlContent) {
    try {
      setIsLoading(true);

      const model = {
        ContentId: contentId,
        Id: sessionObject.userId,
      }

      const response = await new UsersService(Config.API_BASE_URL).AddLeadWatchedContent(model);
      if (response.Error) {
        alert(response.Error.Message);
      }
    } catch (error) {
      alert('Ocorreu um erro ao registrar sua participação, mas vamos te encaminhar para a palestra mesmo assim');
      console.log('error ', error);
    } finally {
      setIsLoading(false);

      window.open(urlContent, '_blank');
    }
  }

  function handleChangeName(e) {
    setName(e.target.value);

    if (!e.target.value) {
      setError({ field: 'name', message: 'Obrigatório' });
    }
    else {
      removeError('name');
    }
  }

  function handleChangeState(e) {
    setState(e.target.value);

    if (!e.target.value) {
      setError({ field: 'state', message: 'Obrigatório' });
    }
    else {
      removeError('state');
    }
  }

  function handleChangeCity(e) {
    setCity(e.target.value);

    if (!e.target.value) {
      setError({ field: 'city', message: 'Obrigatório' });
    }
    else {
      removeError('city');
    }
  }

  function handleChangeCpf(e) {
    setCpf(e.target.value);

    if (!e.target.value) {
      setError({ field: 'cpf', message: 'Obrigatório' });
    }
    else if (!e.target.value.length) {
      setError({ field: 'cpf', message: 'Obrigatório' });
    }
    else {
      removeError('cpf');
    }
  }

  function handleCpfOnKeyPress(e) {
    let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }
    var len = input.value.length;
    // If we're at a particular place, let the user type the slash
    if (len !== 1 || len !== 3) {
      if (e.charCode === 47) {
        e.preventDefault();
      }
    }

    if (e.key !== 'Backspace') {
      // If they don't add the slash, do it for them...
      if (len === 3) {
        input.value += '.';
      }

      // If they don't add the slash, do it for them...
      if (len === 7) {
        input.value += '.';
      }

      if (len === 11) {
        input.value += '-';
      }

      if (len > 13) {
        return false;
      }
    }
  }

  function handleCnpjOnKeyPress(e) {
    let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }
    var len = input.value.length;
    // If we're at a particular place, let the user type the slash
    if (len !== 1 || len !== 3) {
      if (e.charCode === 47) {
        e.preventDefault();
      }
    }

    if (e.key !== 'Backspace') {
      // If they don't add the slash, do it for them...
      if (len === 2) {
        input.value += '.';
      }

      // If they don't add the slash, do it for them...
      if (len === 6) {
        input.value += '.';
      }

      if (len === 10) {
        input.value += '/';
      }

      if (len === 15) {
        input.value += '-';
      }

      if (len > 18) {
        return false;
      }
    }
  }

  function handleChangeRealtorType(e) {
    setRealtorType(e.target.value);

    if (!e.target.value) {
      setError({ field: 'realtorType', message: 'Obrigatório' });
    }
    else {
      removeError('realtorType');
    }
  }

  function handleChangeCreci(e) {
    setCreci(e.target.value);

    if (!e.target.value) {
      setError({ field: 'creci', message: 'Obrigatório' });
    }
    else {
      removeError('creci');
    }
  }

  function handleChangeEmail(e) {
    setEmail(e.target.value);

    if (!e.target.value) {
      setError({ field: 'email', message: 'Obrigatório' });
    }
    else if (!isEmailValid(e.target.value)) {
      setError({ field: 'email', message: 'E-mail inválido' });
    }
    else {
      removeError('email');
    }
  }

  function handleChangeConfirmEmail(e) {
    setConfirmeEmail(e.target.value);

    if (!e.target.value) {
      setError({ field: 'confirmEmail', message: 'Obrigatório' });
    }
    if (e.target.value !== email) {
      setError({ field: 'confirmEmail', message: 'Os valores devem ser iguais' });
    }
    else {
      removeError('confirmEmail');
    }
  }

  // function handleChangePassword(e) {
  //   setPassword(e.target.value);

  //   if (!e.target.value) {
  //     setError({ field: 'password', message: 'Obrigatório' });
  //   }
  //   else {
  //     removeError('password');
  //   }
  // }

  // function handleChangeConfirmPassword(e) {
  //   setConfirmPassword(e.target.value);

  //   if (!e.target.value) {
  //     setError({ field: 'confirmPassword', message: 'Obrigatório' });
  //   }
  //   else {
  //     removeError('confirmPassword');
  //   }
  // }

  function handleChangePhoneNumber(e) {
    setPhoneNumber(e.target.value);

    if (!e.target.value) {
      setError({ field: 'phoneNumber', message: 'Obrigatório' });
    }
    else {
      removeError('phoneNumber');
    }
  }

  function handleChangeCompanyName(e) {
    setCompanyName(e.target.value);

    // if (!e.target.value) {
    //   setError({ field: 'companyName', message: 'Obrigatório' });
    // }
    // else {
    //   removeError('companyName');
    // }
  }

  function handleChangeJobDescription(e) {
    setJobDescription(e.target.value);

    // if (!e.target.value) {
    //   setError({ field: 'jobDescription', message: 'Obrigatório' });
    // }
    // else {
    //   removeError('jobDescription');
    // }
  }

  function handleChangeCnpj(e) {
    setCnpj(e.target.value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (
      isFormValid &&
      name &&
      email &&
      // password &&
      city &&
      cpf &&
      creci &&
      phoneNumber &&
      realtorType &&
      state
    ) {
      try {
        setIsLoading(true);
        const response = await new LeadsService(Config.API_BASE_URL).Create({
          Name: name,
          Email: email,
          // Password: password,
          CityId: city,
          Cnpj: cnpj,
          CompanyName: companyName,
          Cpf: cpf,
          Creci: creci,
          JobDescription: jobDescription,
          PhoneNumber: phoneNumber,
          RealtorType: realtorType,
          StateId: state,
        });

        if (response.Error) {
          // alert("Ocorreu um erro ao cadastrar usuário");
          setIsModalErrorOpen(true);
        }
        else {
          setIsModalSucessOpen(true);
          // alert("Cadastro realizado com sucesso");
        }
      } catch (error) {
        console.log('error ', error);
      } finally {
        setIsLoading(false);
      }
    }
  }

  function handleSucessModal() {
    window.open('/', '_self');
  }

  function hadleCloseModal() {
    setIsModalErrorOpen(false);
    setIsModalSucessOpen(false);
  }

  // // const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
  // const EventDate = new Date(2024, 5, 18).getTime();
  // // const NOW_IN_MS = new Date().getTime();

  // const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;
  // const dateTimeAfterThreeDays = NOW_IN_MS + EventDate;
  // const dateTimeAfterThreeDays = EventDate;

  return (
    <>
      <Loader isLoading={isLoading} />
      <ModalEmail
        title='Cadastro realizado com sucesso'
        body='Enviamos uma confirmação para você por e-mail'
        isVisible={isModalSucessOpen}
        // isVisible={true}
        onConfirm={handleSucessModal}
      // onCancel={hadleCloseModal}
      />
      <Modal
        title='Ocorreu um erro ao cadastrar'
        body='Caso já tenha se cadastrado com este e-mail, verifique a sua caixa de entrada e localize nosso e-mail de confirmação.'
        danger
        isVisible={isModalErrorOpen}
        onConfirm={hadleCloseModal}
      // onCancel={hadleCloseModal}
      />
      <body className="body">
        {(userIsLogedIn) && (
          <>
            <div className='logged-in-div'>

            </div>
          </>
        )}
        {(!userIsLogedIn) && (
          <>
            <div className="principal">
              <div className="principal">
                <div className="w-container">
                  <div className="w-row">
                    {/* <div className="w-col w-col-6">
                  <div className="div-block-2">
                    <img className="imgBanner" src={LogoSaber2} alt=""></img>
                  </div>
                </div> */}
                    <div className="w-col">
                      <div className="div-block-2">
                        {/* <img className="imgBanner" src={LogoSaber2} alt=""></img> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="container-3 w-container">
          <h3 className="heading text-block2">Estamos ao vivo!</h3>
          <div className="videos-background" align="center">
            <iframe width="80%" height="450px" src="https://www.youtube.com/embed/<?=$link;?>" title="YouTube video player" frameborder="0" allow="autoplay; picture-in-picture" allowfullscreen></iframe>
            <a href="https://www.youtube.com/watch?v=<?=$link;?>" target="_blank"
              style={{
                width: '200px',
                marginTop: '15px'
              }}
              className="button w-button" rel="noreferrer">ASSISTIR AGORA </a>
          </div>
        </div> */}
            {/* {(!userIsLogedIn) && (
              <>
                <div id="convite" className="row" align="center">
                  <div className="w-container" >
                    <h3 className="heading text-block2 ">
                      Dia 16 de maio
                    </h3>
                    <section className="videos-carousel slider" data-arrows="true">
                      <div>
                        <div className="videos-background">
                          <iframe className="iframe_videos" src="https://www.youtube.com/embed/4oUImrsYlLA" title="YouTube video player" frameborder="0" allow="autoplay; picture-in-picture" allowfullscreen></iframe>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div id="convite" className="row" align="center">
                  <div className="w-container" >
                    <h3 className="heading text-block2 ">
                      Dia 17 de maio
                    </h3>
                    <section className="videos-carousel slider" data-arrows="true">
                      <div>
                        <div className="videos-background">
                          <iframe className="iframe_videos" src="https://www.youtube.com/embed/pVQgqFOxtDc" title="YouTube video player" frameborder="0" allow="autoplay; picture-in-picture" allowfullscreen></iframe>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div id="convite" className="row" align="center">
                  <div className="w-container" >
                    <h3 className="heading text-block2 ">
                      Dia 18 de maio
                    </h3>
                    <section className="videos-carousel slider" data-arrows="true">
                      <div>
                        <div className="videos-background">
                          <iframe className="iframe_videos" src="https://www.youtube.com/embed/SzPJpXjHfhY" title="YouTube video player" frameborder="0" allow="autoplay; picture-in-picture" allowfullscreen></iframe>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </>
            )} */}

            <div id="sobre">
              {/* <div className="container-3 w-container">
                <h3 className="small-text yellow">Corretor de imóveis</h3>
                <div className="big-text">Ser e pertencer</div>
                <br />
                <div className="text-block">Corretor de imóveis, você está pronto para 5ª edição do maior evento online do mercado imobiliário?
                  <br />
                  <br />
                  O Sistema COFECI-CRECI e o SEBRAE se reúnem mais uma vez para trazer conteúdo de alta qualidade. Especialistas renomados em inovação e tecnologia trarão as últimas tendências de mercado. Inscreva-se e eleve o seu negócio para o próximo nível: o futuro!
                  <br />
                  <br />
                  <br />
                </div>
                <div className="object1">
                  <img src="images/objeto amarelo.png" loading="lazy" width="262" sizes="(max-width: 479px) 100vw, 262px" srcset="images/objeto amarelo.png 500w, images/objeto amarelo.png 524w" alt=""></img>
                </div>
              </div> */}

              <div className='sobre-container'>
                <div className='row'>
                  <div className='col-md-5'>
                    <div className='big-red-text'>
                      Corretor de imóveis:
                    </div>
                    <div className='bigger-purple-text'>
                      Ser e pertencer
                    </div>
                    <div className='underline-purple-text'>
                    </div>
                    {/* <div className='clock-container'>
                      <div className='top-part'>
                        ⏰ Faltam:
                      </div>
                      <div className='bottom-part'>
                        <CountdownTimer targetDate={dateTimeAfterThreeDays} />
                      </div>
                    </div> */}


                  </div>
                  <div className='col-md-7'>
                    <div className='top-section-text'>
                      Ser corretor de imóveis vai além de fechar negócios. É estar na linha de frente, mediando desejos e realidades, construindo pontes entre sonhos e conquistas tangíveis. É uma jornada que exige não apenas habilidades técnicas, mas também empatia, dedicação e visão.
                    </div>

                    <div className='top-section-text'>
                      É com orgulho que o Sistema COFECI-CRECI e o SEBRAE se reúnem para a 5ª edição do Saber Imobiliário, <b>entre os dias 18, 19 e 20 de Junho</b>, que vai celebrar a profissão Corretor de Imóveis. O evento online é gratuito. Garanta a sua vaga.
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </>
        )}

        <div id="programacao">
          <div className="">
            {/* <div className="w-container"> */}
            {(!userIsLogedIn) && (
              <div className="big-text">Programação</div>
            )}
            <div data-duration-in="300" data-duration-out="100" className="tabs-2 w-tabs">
              <div className="w-tab-menu">
                <div className="tab-link-space-25">
                  <a href="#t" data-w-tab="25"
                    className={`w-inline-block w-tab-link ${(activeTabPane === 1 ? "w--current" : "")}`}>
                    <div
                      className="text-block-11"
                      onClick={() => { setActiveTabPane(1); }}
                    >
                      18 de Junho <br />
                      <div className='small'>Dia 1</div>
                    </div>
                  </a>
                </div>
                <div className="tab-link-space-29">
                  <a href="#t" data-w-tab="26"
                    className={`tab-link-01-12 w-inline-block w-tab-link ${(activeTabPane === 2 ? "w--current" : "")}`}>
                    <div
                      className="text-block-11"
                      onClick={() => { setActiveTabPane(2); }}
                    >
                      19 de Junho <br />
                      <div className='small'>Dia 2</div>
                    </div>
                  </a>
                </div>
                <div className="tab-link-space-21">
                  <a href="#t" data-w-tab="27"
                    className={`tab-link-02-12 w-inline-block w-tab-link ${(activeTabPane === 3 ? "w--current" : "")}`}>
                    <div
                      className="text-block-12"
                      onClick={() => { setActiveTabPane(3); }}
                    >
                      20 de Junho <br />
                      <div className='small'>Dia 3</div>
                    </div>
                  </a>
                </div>
                {/* <div align="right" className="tab-link-space-25-2">
                  <a href="#t" data-w-tab="28" className="tab-link-03-12 w-inline-block w-tab-link">
                    <div className="text-block-13">DIA 19</div>
                  </a>
                </div> */}
              </div>
              <div className="w-tab-content">
                {(activeTabPane === 1) && (
                  <div data-w-tab="25" className="tab-pane-tab-1-2 w-tab-pane w--tab-active">
                    <div className="columns w-row container smaller">
                      <div className="w-col w-col-4">
                        <div className="div-block-3">
                          <img src={Palestrante1} loading="lazy" sizes="(max-width: 479px) 90vw, (max-width: 767px) 85vw, 30vw" width="500"
                            alt="Palestrante"></img>
                        </div>
                      </div>
                      <div className="w-col w-col-8">
                        <div className="div-block-4">
                          <div className="text-block-15">
                            <img src={Clock} loading="lazy" alt="Clock"></img>
                            19h30 às 21h30
                          </div>
                          <h4 className="heading-3"><strong>A mentalidade do corretor de sucesso!</strong></h4>
                          <div className="text-block-2">
                            <div className="div-line-point">
                              Ricardo Martins é um destacado expoente no mercado imobiliário nacional. Suas redes sociais acumulam mais de 90 milhões de visualizações mensais. Até março deste ano, foi sócio da My Broker, empresa que movimentou mais de 3 bilhões de reais em vendas no ano passado. Reconhecido como o maior treinador de profissionais do setor imobiliário brasileiro, Ricardo já formou mais de 15 mil alunos. Atualmente, conta com mais de 3 milhões de seguidores em suas redes sociais.
                              <br />
                              <br />
                              Ricardo Martins discutirá os aspectos que transcendem as percepções comuns sobre a carreira de corretor de imóveis. Para além da imagem estereotipada que inclui vestimentas e bens materiais, ele abordará um dos elementos mais cruciais para o êxito nesta profissão: a mentalidade do corretor.
                            </div>
                          </div>
                          <div className='button-container'>
                            {(isDownloadButton) ? (
                              <>
                                <a href="https://bit.ly/SaberV-Resumo-1" className="button w-button">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <mask id="mask0_37_2417" style={{
                                      maskType: "alpha"
                                    }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                      <rect width="24" height="24" fill="#D9D9D9" />
                                    </mask>
                                    <g mask="url(#mask0_37_2417)">
                                      <path d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6Z" fill="white" />
                                    </g>
                                  </svg>
                                  Download material</a>
                              </>
                            ) : (
                              <>
                                <a href="https://bit.ly/Dia1-SaberImobV" className="button w-button">
                                  Assista a palestra</a>
                              </>
                            )}
                          </div>
                          {(userIsLogedIn) && (
                            <div className="text-block-16">
                              <a
                                href="#l"
                                onClick={() => {
                                  AddLeadWatchedContent(1, "https://youtube.com/live/4oUImrsYlLA?feature=share");
                                }}
                                class="button w-button"
                              >
                                Assistir palestra</a>
                              {/* <a href="/" class="button-2 w-button">Baixar material</a> */}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {(activeTabPane === 2) && (
                  <div data-w-tab="26" className="tab-pane-tab-2-2 w-tab-pane w--tab-active">
                    <div className="columns w-row container smaller">
                      <div className="w-col w-col-4">
                        <div className="div-block-3">
                          <img src={Palestrante2} loading="lazy" sizes="(max-width: 479px) 90vw, (max-width: 767px) 85vw, 30vw" width="500"
                            alt="Palestrante"></img>
                        </div>
                      </div>
                      <div className="w-col w-col-8">
                        <div className="div-block-4">
                          <div className="text-block-15">
                            <img src={Clock} loading="lazy" alt="Clock"></img>
                            19h30 às 21h30
                          </div>
                          <h4 className="heading-3"><strong>
                            Mercado Internacional: Transforme Desafios em Oportunidades
                          </strong></h4>
                          <div className="text-block-2">
                            <div className="div-line-point">
                              Marta Faustino é CEO do DTB Group, que faturou mais de 25 milhões de reais em 2023. Pioneira na integração entre corretores brasileiros e Realtors® nos EUA, ela capacita corretores com 19 anos de experiência no mercado americano. Criadora do Flórida Connection Road Show e palestrante renomada, Marta demonstra que é possível construir uma carreira internacional do zero, alcançando mais de 27 milhões de VGV no primeiro semestre de 2024.
                              <br />
                              <br />
                              Nesta palestra, Marta Faustino ensinará os 8 tópicos essenciais para o sucesso do corretor internacional. Com 19 anos de experiência no mercado americano, ela compartilhará estratégias práticas, destacando a importância da mudança de mindset. Marta apresentará cases de sucesso e insights valiosos para aumentar os ganhos e dolarizar o patrimônio dos clientes.
                            </div>
                          </div>
                          <div className='button-container'>
                            {(isDownloadButton) ? (
                              <>
                                <a href="https://bit.ly/SaberV-Resumo-2" className="button w-button">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <mask id="mask0_37_2417" style={{
                                      maskType: "alpha"
                                    }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                      <rect width="24" height="24" fill="#D9D9D9" />
                                    </mask>
                                    <g mask="url(#mask0_37_2417)">
                                      <path d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6Z" fill="white" />
                                    </g>
                                  </svg>
                                  Download material</a>
                              </>
                            ) : (
                              <>
                                <a href="https://bit.ly/Dia2-SaberImobV" className="button w-button">
                                  Assista a palestra</a>
                              </>
                            )}
                          </div>
                          {(userIsLogedIn) && (
                            <div className="text-block-16">
                              <a
                                href="#l"
                                disabled={true}
                                onClick={() => {
                                  AddLeadWatchedContent(2, "https://youtube.com/live/pVQgqFOxtDc?feature=share");
                                }}
                                class="button w-button"
                              >
                                Assistir palestra</a>
                              {/* <a href="/" class="button-2 w-button">Baixar material</a> */}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {(activeTabPane === 3) && (
                  <div data-w-tab="27" className="tab-pane-tab-3-2 w-tab-pane w--tab-active">
                    <div className="columns w-row container smaller">
                      <div className="w-col w-col-4">
                        <div className="div-block-3">
                          <img src={Palestrante3} loading="lazy" sizes="(max-width: 479px) 90vw, (max-width: 767px) 85vw, 30vw" width="500"
                            alt="Palestrante"></img>
                        </div>
                      </div>
                      <div className="w-col w-col-8">
                        <div className="div-block-4">
                          <div className="text-block-15">
                            <img src={Clock} loading="lazy" alt="Clock"></img>
                            19h30 às 21h30
                          </div>
                          <h4 className="heading-3"><strong>Somos um produto! Destaque-se na prateleira do mercado.</strong></h4>
                          <div className="text-block-2">
                            <div className="div-line-point">
                              Raquel Lacerda, com mais de 15 anos de mercado, é uma corretora e empreendedora de sucesso. Fundadora da imobiliária Sua Casa Digital, mulher e mãe, coleciona diversas vitórias e conquistas no mercado do Rio de Janeiro. Gerente campeã consecutiva por diversas empresas, hoje lidera um time próprio e seleto de corretores de alto padrão.
                              <br />
                              <br />
                              Raquel Lacerda falará para os corretores de imóveis como um profissional de sucesso se destaca e se posiciona no mercado de imóveis de luxo. Além disso, a importância de se tornar um corretor atrativo e valioso aos olhos dos clientes.
                            </div>
                          </div>
                          <div className='button-container'>
                            {(isDownloadButton) ? (
                              <>
                                <a href="https://bit.ly/SaberV-Resumo-3" className="button w-button">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <mask id="mask0_37_2417" style={{
                                      maskType: "alpha"
                                    }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                      <rect width="24" height="24" fill="#D9D9D9" />
                                    </mask>
                                    <g mask="url(#mask0_37_2417)">
                                      <path d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6Z" fill="white" />
                                    </g>
                                  </svg>
                                  Download material</a>
                              </>
                            ) : (
                              <>
                                <a href="https://bit.ly/Dia3-SaberImobV" className="button w-button">
                                  Assista a palestra</a>
                              </>
                            )}
                          </div>
                          {(userIsLogedIn) && (
                            <div className="text-block-16">
                              <a
                                href="#l"
                                disabled={true}
                                onClick={() => {
                                  AddLeadWatchedContent(3, "https://youtube.com/live/SzPJpXjHfhY?feature=share");
                                }}
                                class="button w-button"
                              >
                                Assistir palestra</a>
                              {/* <a href="/" class="button-2 w-button">Baixar material</a> */}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* <div data-w-tab="28" className="tab-pane-tab-4-2 w-tab-pane">
                  <div className="columns w-row container smaller">
                    <div className="w-col w-col-4">
                      <div className="div-block-3">
                        <img src="images/palestrantes/28.png" loading="lazy" sizes="(max-width: 479px) 90vw, (max-width: 767px) 85vw, 30vw" width="500" srcset="images/palestrantes/28.png 500w, images/palestrantes/28.png 800w, images/palestrantes/28.png 1080w"
                          alt=""></img>
                      </div>
                      <div className="text-block-15">19h30 às 21h30</div>
                    </div>
                    <div className="w-col w-col-8">
                      <div className="div-block-4">
                        <h4 className="heading-5"><strong>Tendência e Economia para Setor Imobiliário</strong></h4>
                        <div className="text-block-2">Na palestra de Ricardo Amorim, você saberá sobre:<br />
                          <div className="div-line-point-2">- O bom desempenho do mercado imobiliário desde a pandemia</div>
                          <div className="div-line-point-2">- Os impactos da alta dos juros</div>
                          <div className="div-line-point-2">- Guerra na Ucrânia: Impactos no Brasil e no Mundo</div>
                          <div className="div-line-point-2">- Transformação digital: oportunidades e desafios para o mercado imobiliário</div>
                          <div className="div-line-point-2">- Alta da inflação e queda do dólar</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {(!userIsLogedIn) && (
          <>
            {/* <div className="object2">
          <img src="images/objeto vermelho.png" loading="lazy" width="262" sizes="(max-width: 479px) 100vw, 262px" srcset="images/objeto vermelho.png 500w, images/objeto vermelho.png 524w" alt=""></img>
        </div> */}
            <div className="section-2" id="subscribe-section">
              <div className="w-container w-container-section-2">
                <div className="heading text-block2">
                  Garanta sua vaga hoje!
                </div>
                <div className="text-block black">
                  Preencha o formulário abaixo para inscrever-se gratuitamente. O cadastro é individual.
                </div>
                <form
                  onSubmit={handleSubmit}
                  className="form-subscribe"
                  // action="#"
                  id="form-subscribe"
                // method="POST"
                >
                  <div className='form-desktop'>
                    <div className="div-table">
                      <div className="div-table-body">
                        <div className="div-table-row">
                          <div className="div-table-cell">
                            <FormGroup
                              error={getErrorMessageByFieldName('name')}
                            >
                              <input
                                type="text"
                                className="css-input name-user"
                                name="name-user"
                                id="name-user"
                                placeholder="Nome"
                                required
                                value={name}
                                onChange={handleChangeName}
                              />
                            </FormGroup>
                          </div>
                          <div className="div-table-cell">
                            <FormGroup
                              error={getErrorMessageByFieldName('cpf')}
                            >
                              <input
                                type="text"
                                maxlength="14"
                                className="css-input cpf-user"
                                name="cpf-user"
                                id="cpf-user"
                                placeholder="CPF"
                                required
                                value={cpf}
                                onChange={handleChangeCpf}
                                onKeyPress={handleCpfOnKeyPress}
                                onKeyUp={handleCpfOnKeyPress}
                              />
                            </FormGroup>
                          </div>
                          <div className="div-table-cell">
                            <FormGroup
                              error={getErrorMessageByFieldName('phoneNumber')}
                            >
                              <input
                                type="text"
                                className="css-input phone-user"
                                name="phone-user"
                                id="phone-user"
                                placeholder="Telefone"
                                required
                                value={phoneNumber}
                                onChange={handleChangePhoneNumber}
                              />
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="div-table">
                      <div className="div-table-body">
                        <div className="div-table-row">
                          <div className="div-table-cell">
                            <FormGroup
                              error={getErrorMessageByFieldName('email')}
                            >
                              <input
                                type="email"
                                className="css-input email-user"
                                name="email-user"
                                id="email-user"
                                placeholder="E-mail"
                                required
                                value={email}
                                onChange={handleChangeEmail}
                              />
                            </FormGroup>
                          </div>
                          <div className="div-table-cell">
                            <FormGroup
                              error={getErrorMessageByFieldName('confirmEmail')}
                            >
                              <input
                                type="email"
                                className="css-input email-user2"
                                name="email-user2"
                                id="email-user2"
                                placeholder="Confirme seu e-mail"
                                required
                                value={confirmEmail}
                                onChange={handleChangeConfirmEmail}
                              />
                            </FormGroup>
                          </div>
                          <div className="div-table-cell">
                            <FormGroup
                              error={getErrorMessageByFieldName('creci')}
                            >
                              <input
                                type="text"
                                className="css-input creci-user"
                                name="creci-user"
                                id="creci-user"
                                placeholder="CRECI"
                                required
                                value={creci}
                                onChange={handleChangeCreci}
                              />
                            </FormGroup>
                          </div>
                          <div className="div-table-cell">
                            <FormGroup
                              error={getErrorMessageByFieldName('realtorType')}
                            >
                              <select
                                id="isCorretor-user"
                                name="isCorretor-user"
                                className="css-input isCorretor-user"
                                placeholder="Corretor ou estagiário?"
                                required
                                value={realtorType}
                                onChange={handleChangeRealtorType}
                              >
                                <option value="">Corretor ou estagiário?</option>
                                <option value="1">Corretor</option>
                                <option value="2">Estagiário</option>
                                <option value="3">Estudante</option>
                              </select>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="div-table">
                      <div className="div-table-body">
                        <div className="div-table-row">
                          {/* <div className="div-table-cell">
                          <FormGroup
                            error={getErrorMessageByFieldName('password')}
                          >
                            <input
                              type="password"
                              className="css-input pass-user"
                              name="pass-user"
                              id="pass-user"
                              placeholder="CRIE SUA SENHA"
                              required
                              value={password}
                              onChange={handleChangePassword}
                            />
                          </FormGroup>
                        </div>
                        <div className="div-table-cell">
                          <FormGroup
                            error={getErrorMessageByFieldName('confirmPassword')}
                          >
                            <input
                              type="password"
                              className="css-input pass2-user"
                              name="pass2-user"
                              id="pass2-user"
                              placeholder="CONFIRME SUA SENHA"
                              required
                              value={confirmPassword}
                              onChange={handleChangeConfirmPassword}
                            />
                          </FormGroup>
                        </div> */}
                          <div className="div-table-cell">
                            <FormGroup
                              error={getErrorMessageByFieldName('companyName')}
                            >
                              <input
                                type="text"
                                className="css-input business-name-user" name="business-name-user" id="business-name-user" placeholder="Nome da empresa (Se houver)"
                                value={companyName}
                                onChange={handleChangeCompanyName}
                              />
                            </FormGroup>
                          </div>
                          <div className="div-table-cell">
                            <FormGroup
                              error={getErrorMessageByFieldName('jobDescription')}
                            >
                              <input type="text" className="css-input role-user" name="role-user" id="role-user" placeholder="Cargo ou Função (Se houver)"
                                value={jobDescription}
                                onChange={handleChangeJobDescription}
                              />
                            </FormGroup>
                          </div>
                          <div className="div-table-cell">
                            <FormGroup
                              error={getErrorMessageByFieldName('cnpj')}
                            >
                              <input type="text" className="css-input cnpj-user" name="cnpj-user" id="cnpj-user" placeholder="CNPJ (Se houver)"
                                value={cnpj}
                                onChange={handleChangeCnpj}
                                onKeyUp={handleCnpjOnKeyPress}
                                onKeyPress={handleCnpjOnKeyPress}
                              />
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="div-table">
                      <div className="div-table-body">
                        <div className="div-table-row">
                          <div className="div-table-cell stateDiv">
                            <FormGroup
                              error={getErrorMessageByFieldName('state')}
                            >
                              <select
                                id="state-user"
                                name="state-user-uf"
                                className="css-input state-user"
                                placeholder="UF"
                                required
                                value={state}
                                onChange={handleChangeState}
                              >
                                <option value="">Estado</option>
                                {states && (
                                  states.map((state) => (
                                    // <option key={state.Id} value={state.Id}>{state.Acronym}</option>
                                    <option key={state.Id} value={state.Id}>{state.Name}</option>
                                  ))
                                )}
                                {/* <option value="12">AC</option>
                                <option value="27">AL</option>
                                <option value="13">AM</option>
                                <option value="16">AP</option>
                                <option value="29">BA</option>
                                <option value="23">CE</option>
                                <option value="53">DF</option>
                                <option value="32">ES</option>
                                <option value="52">GO</option>
                                <option value="21">MA</option>
                                <option value="31">MG</option>
                                <option value="50">MS</option>
                                <option value="51">MT</option>
                                <option value="15">PA</option>
                                <option value="25">PB</option>
                                <option value="26">PE</option>
                                <option value="22">PI</option>
                                <option value="41">PR</option>
                                <option value="33">RJ</option>
                                <option value="24">RN</option>
                                <option value="11">RO</option>
                                <option value="14">RR</option>
                                <option value="43">RS</option>
                                <option value="42">SC</option>
                                <option value="28">SE</option>
                                <option value="35">SP</option>
                                <option value="17">TO</option> */}
                              </select>
                            </FormGroup>
                          </div>
                          <div className="div-table-cell cityDiv">
                            <FormGroup
                              error={getErrorMessageByFieldName('city')}
                            >
                              <select
                                id="city-user"
                                name="city-user"
                                className="css-input city-user"
                                placeholder="Cidade"
                                required
                                value={city}
                                onChange={handleChangeCity}
                              >
                                <option value="">Cidade</option>
                                {cities && (
                                  cities.map((city) => (
                                    <option key={city.Id} value={city.Id}>{city.Name}</option>
                                  ))
                                )}
                              </select>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='form-mobile'>
                    <div className="row">
                      <div className="col-md-12">
                        <FormGroup
                          error={getErrorMessageByFieldName('name')}
                        >
                          <input
                            type="text"
                            className="css-input name-user"
                            name="name-user"
                            id="name-user-mobile"
                            placeholder="Nome"
                            required
                            value={name}
                            onChange={handleChangeName}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-12">
                        <FormGroup
                          error={getErrorMessageByFieldName('cpf')}
                        >
                          <input
                            type="text"
                            maxlength="14"
                            className="css-input cpf-user"
                            name="cpf-user"
                            id="cpf-user-mobile"
                            placeholder="CPF"
                            required
                            value={cpf}
                            onChange={handleChangeCpf}
                            onKeyPress={handleCpfOnKeyPress}
                            onKeyUp={handleCpfOnKeyPress}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-12">
                        <FormGroup
                          error={getErrorMessageByFieldName('phoneNumber')}
                        >
                          <input
                            type="text"
                            className="css-input phone-user"
                            name="phone-user"
                            id="phone-user-mobile"
                            placeholder="Telefone"
                            required
                            value={phoneNumber}
                            onChange={handleChangePhoneNumber}
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <FormGroup
                          error={getErrorMessageByFieldName('email')}
                        >
                          <input
                            type="email"
                            className="css-input email-user"
                            name="email-user"
                            id="email-user-mobile"
                            placeholder="E-mail"
                            required
                            value={email}
                            onChange={handleChangeEmail}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-12">
                        <FormGroup
                          error={getErrorMessageByFieldName('confirmEmail')}
                        >
                          <input
                            type="email"
                            className="css-input email-user2"
                            name="email-user2"
                            id="email-user2-mobile"
                            placeholder="Confirme seu e-mail"
                            required
                            value={confirmEmail}
                            onChange={handleChangeConfirmEmail}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-12">
                        <FormGroup
                          error={getErrorMessageByFieldName('creci')}
                        >
                          <input
                            type="text"
                            className="css-input creci-user"
                            name="creci-user"
                            id="creci-user-mobile"
                            placeholder="CRECI"
                            required
                            value={creci}
                            onChange={handleChangeCreci}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-12">
                        <FormGroup
                          error={getErrorMessageByFieldName('realtorType')}
                        >
                          <select
                            id="isCorretor-user-mobile"
                            name="isCorretor-user"
                            className="css-input isCorretor-user"
                            placeholder="Corretor ou estagiário?"
                            required
                            value={realtorType}
                            onChange={handleChangeRealtorType}
                          >
                            <option value="">Corretor ou estagiário?</option>
                            <option value="1">Corretor</option>
                            <option value="2">Estagiário</option>
                            <option value="3">Estudante</option>
                          </select>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <FormGroup
                          error={getErrorMessageByFieldName('companyName')}
                        >
                          <input
                            type="text"
                            className="css-input business-name-user" name="business-name-user" id="business-name-user-mobile" placeholder="Nome da empresa (Se houver)"
                            value={companyName}
                            onChange={handleChangeCompanyName}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-12">
                        <FormGroup
                          error={getErrorMessageByFieldName('jobDescription')}
                        >
                          <input type="text" className="css-input role-user" name="role-user" id="role-user-mobile" placeholder="Cargo ou Função (Se houver)"
                            value={jobDescription}
                            onChange={handleChangeJobDescription}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-12">
                        <FormGroup
                          error={getErrorMessageByFieldName('cnpj')}
                        >
                          <input type="text" className="css-input cnpj-user" name="cnpj-user" id="cnpj-user-mobile" placeholder="CNPJ"
                            value={cnpj}
                            onChange={handleChangeCnpj}
                            onKeyUp={handleCnpjOnKeyPress}
                            onKeyPress={handleCnpjOnKeyPress}
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <FormGroup
                          error={getErrorMessageByFieldName('state')}
                        >
                          <select
                            id="state-user-mobile"
                            name="state-user-uf"
                            className="css-input state-user"
                            placeholder="UF"
                            required
                            value={state}
                            onChange={handleChangeState}
                          >
                            <option value="">Estado</option>
                            {states && (
                              states.map((state) => (
                                // <option key={state.Id} value={state.Id}>{state.Acronym}</option>
                                <option key={state.Id} value={state.Id}>{state.Name}</option>
                              ))
                            )}
                            {/* <option value="12">AC</option>
                            <option value="27">AL</option>
                            <option value="13">AM</option>
                            <option value="16">AP</option>
                            <option value="29">BA</option>
                            <option value="23">CE</option>
                            <option value="53">DF</option>
                            <option value="32">ES</option>
                            <option value="52">GO</option>
                            <option value="21">MA</option>
                            <option value="31">MG</option>
                            <option value="50">MS</option>
                            <option value="51">MT</option>
                            <option value="15">PA</option>
                            <option value="25">PB</option>
                            <option value="26">PE</option>
                            <option value="22">PI</option>
                            <option value="41">PR</option>
                            <option value="33">RJ</option>
                            <option value="24">RN</option>
                            <option value="11">RO</option>
                            <option value="14">RR</option>
                            <option value="43">RS</option>
                            <option value="42">SC</option>
                            <option value="28">SE</option>
                            <option value="35">SP</option>
                            <option value="17">TO</option> */}
                          </select>
                        </FormGroup>
                      </div>
                      <div className="col-md-12">
                        <FormGroup
                          error={getErrorMessageByFieldName('city')}
                        >
                          <select
                            id="city-user-mobile"
                            name="city-user"
                            className="css-input city-user"
                            placeholder="Cidade"
                            required
                            value={city}
                            onChange={handleChangeCity}
                          >
                            <option value="">Cidade</option>
                            {cities && (
                              cities.map((city) => (
                                <option key={city.Id} value={city.Id}>{city.Name}</option>
                              ))
                            )}
                          </select>
                        </FormGroup>
                      </div>
                    </div>
                  </div>

                  <div align="center">
                    <div className="text-small">
                      Em conformidade com à Lei n 13. 709. Lei Geral de Proteção de Dados Pessoais (LGPD). estou ciente de que os dados acima fornecidos, serão utilizados exclusivamente pelo SISTEMA COFECI-CRECI, HOMER e SEBRAE/PR. para prestação dos serviços e informações decorrentes da minha participação no evento acima descrito. bem como. para oferecer conteúdo e informações relevantes ao meu negócio e setor de atuação.
                    </div>
                  </div>

                  <div className="submit" align="center">
                    <button
                      type="button"
                      className="submit-button"
                      id="submit-button"
                      name="submit-button"
                      disabled={(!(
                        isFormValid &&
                        name &&
                        email &&
                        // password &&
                        city &&
                        cpf &&
                        creci &&
                        phoneNumber &&
                        realtorType &&
                        state
                      ))}
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >Confirmar inscrição</button>
                  </div>
                </form>
              </div>
            </div>

            {/* <div id="convite" className="row" align="center">
              <div className="" style={{
                paddingLeft: '10%',
                paddingRight: '10%',
              }}>
                <div className="invitation">
                  Confira o Blog do Saber Imobiliário:
                </div>
                <div className='video-container row'>
                  <div className='texts-container col-md-6'>
                    <div className='text'>
                      <div className='title'>
                        O que esperar do Saber Imobiliário V?
                      </div>
                      <div className='explanation'>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make...
                      </div>
                      <div className='yellow-link'>
                        <a href='/'>Ler o artigo completo</a>
                      </div>
                    </div>
                    <div className='text'>
                      <div className='title'>
                        O que esperar do Saber Imobiliário V?
                      </div>
                      <div className='explanation'>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make...
                      </div>
                      <div className='yellow-link'>
                        <a href='/'>Ler o artigo completo</a>
                      </div>
                    </div>
                  </div>
                  <div className="videos-background col-md-6">
                    <iframe className="iframe_videos" src="https://www.youtube.com/embed/7FdpLLoDZec" title="YouTube video player" frameborder="0" allow="autoplay; picture-in-picture" allowfullscreen></iframe>
                    <div className='under-video-text'>
                      Assista ao convite do Presidente do Sistema COFECI-CRECI, João Teodoro
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div id="depoimentos" className="section-3 tabs">
          <h3 className="heading text-block3">
            DEPOIMENTOS

          </h3>
          <div className="row" align="center">
            <div className="container-4" >
              <section className="depoimentos-carousel slider" data-arrows="true">
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Foi show. Parabéns pela organização, envio de materiais, tudo transcorreu na mais perfeita ordem. Aplausos.”</div>
                  </div>
                  <div className="text-block-8"><strong>Carlos Antonio Biaya</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Quero parabenizar o Creci-PR e todos os envolvidos nesta parceria com Sebrae, que possibilitou esse excelente evento e nos contemplou com riquíssimas atualizações e informações aumentando nosso conhecimento.”</div>
                  </div>
                  <div className="text-block-8"><strong>Joaquim Firmino da Cruz</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Nós é que agradecemos e parabenizamos a toda equipe que se empenhou em proporcionar este belíssimo e valioso evento. O nosso muito obrigado.”</div>
                  </div>
                  <div className="text-block-8"><strong>Antônio Murata</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Muito obrigada. Todas as palestras foram muito boas.”</div>
                  </div>
                  <div className="text-block-8"><strong>Cleuza</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Obrigado. Que venham outros para somar.”</div>
                  </div>
                  <div className="text-block-8"><strong>Wiliam Rodrigues</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Obrigado pelo presente nesta jornada e pelos conhecimentos adquiridos. Um abraço a todos.”</div>
                  </div>
                  <div className="text-block-8"><strong>Regina Cerioni</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Parabéns pela iniciativa, a capacitação é igual a crescimento profissional.”</div>
                  </div>
                  <div className="text-block-8"><strong>Laudimiro Cavalcanti</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Excelente iniciativa COFECI e participantes! Parabenizo a iniciativa de todos em nos proporcionar tamanha oportunidade! Muito obrigada a todos!”</div>
                  </div>
                  <div className="text-block-8"><strong>Lênia Gestora</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Que palestra FANTÁSTICA! Parabéns pelos conhecimentos e reflexões compartilhadas!”</div>
                  </div>
                  <div className="text-block-8"><strong>Marcelo Oliveira</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Excelente esse evento, muito importante para dignificar nossa categoria, precisamos evoluir e precisamos mudar muitas regras para que nossa categoria seja a primeira a ser chamada na hora de vender um imóvel.”</div>
                  </div>
                  <div className="text-block-8"><strong>Sandra Kovalik</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Um encontro de muitos conhecimentos.”</div>
                  </div>
                  <div className="text-block-8"><strong>Ismael Reis</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Parabéns! Grande aula!”</div>
                  </div>
                  <div className="text-block-8"><strong>Andressa Magalhães</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Tudo excelente, do início ao final. Foi além do esperado. Parabéns!”</div>
                  </div>
                  <div className="text-block-8"><strong>Maria Souto</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Muito legal o tema de hoje, e proveitoso no ramo imobiliário.”</div>
                  </div>
                  <div className="text-block-8"><strong>Antônio Lucídio</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Ótima live, indico aos que querem viver nesse mercado competitivo.”</div>
                  </div>
                  <div className="text-block-8"><strong>Luiz Henrique Costa</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Parabéns ao palestrante pela brilhante palestra de grandes conhecimentos.”</div>
                  </div>
                  <div className="text-block-8"><strong>Sérgio Paulo</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Parabéns! Muitos conhecimentos.”</div>
                  </div>
                  <div className="text-block-8"><strong>Arlene Galvão</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Muito proveitoso, vocês estão de parabéns!”</div>
                  </div>
                  <div className="text-block-8"><strong>Igor PC</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“O nosso muito obrigada ao sistema Cofeci-Creci por mais uma edição saber imobiliário com conteúdo de alto nível. Parabéns Bruna Bruning pelas belas músicas selecionadas, foi muito emocionante! Um evento online do mais alto nível! Capacitação é a chave do sucesso!”</div>
                  </div>
                  <div className="text-block-8"><strong>Thamara Bastos Silva Realtor</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Adoráveis e competentes palestrantes.”</div>
                  </div>
                  <div className="text-block-8"><strong>Lisete H S Kerne</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Muito animada com o que estou aprendendo. Parabéns a todos os participantes do evento!”</div>
                  </div>
                  <div className="text-block-8"><strong>Rosangela Espósito</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Devemos parabenizar a todos envolvidos na execução deste evento, de alto valor a formação dos profissionais corretores. Me sinto honrado nesta profissão!”</div>
                  </div>
                  <div className="text-block-8"><strong>Valcy Silva</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Espetacular a palestra de hoje! Com os temas abordados e as orientações nos diversos momentos da negociação nos traz a possibilidade de fazermos uma reflexão de como tem sido o nosso comportamento diante de uma negativa no momento do fechamento... sem dúvidas a palestra nos trouxe os caminhos que precisamos melhorar para nossa eficácia no mundo dos negócios. Parabéns Guilherme!”</div>
                  </div>
                  <div className="text-block-8"><strong>Jaildo Chavesa</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Palestra sensacional, assunto importante de se aprofundar e ter total conhecimento para uma negociação de sucesso.”</div>
                  </div>
                  <div className="text-block-8"><strong>José Carlos Fraccaroli</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Excelente palestra, conteúdo muito completo que retrata o passo a passo para ter sucesso nos negócios.”</div>
                  </div>
                  <div className="text-block-8"><strong>Junior Pelissari</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Parabéns pela organização e também aos palestrantes.”</div>
                  </div>
                  <div className="text-block-8"><strong>Zolmir Zanella</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Vamos aprender! O conhecimento transforma.”</div>
                  </div>
                  <div className="text-block-8"><strong>Daniel Sousa</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Excelente aprendizado! Gratidão!”</div>
                  </div>
                  <div className="text-block-8"><strong>Lindi Gomes</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Muito interessante todas as palestras, gratidão!”</div>
                  </div>
                  <div className="text-block-8"><strong>Ida Maria</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Está sendo simplesmente maravilhoso! Além de todo aprendizado, ainda contamos com uma belíssima introdução musical!”</div>
                  </div>
                  <div className="text-block-8"><strong>Cicera Zubi</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Parabéns a todos por este grande evento. Foi sensacional!”</div>
                  </div>
                  <div className="text-block-8"><strong>Kleber Maynart</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Parabéns pelo evento! Quatro dias de grande aprendizado e palestrantes maravilhosos.”</div>
                  </div>
                  <div className="text-block-8"><strong>Ana Cristina</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Foi magnífico esse evento, fiquei muito feliz em participar. Obrigado a todos os envolvidos!”</div>
                  </div>
                  <div className="text-block-8"><strong>Marcílio</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Estarei presente, não posso perder. Essa edição do Saber Imobiliário, está um sucesso!”</div>
                  </div>
                  <div className="text-block-8"><strong>Cristino Helder</strong></div>
                </div>
                <div className="depoimentos">
                  <div>
                    <div className="text-block-9">“Sensacional, já pensando no próximo.”</div>
                  </div>
                  <div className="text-block-8"><strong>Rita Cássia</strong></div>
                </div>
              </section>
            </div>
          </div>
        </div> */}

            <div id="depoimentos" className='depoimentos-container'>
              <div className="row" align="center">
                <div className="container containerLogoCreci" >
                  <section className="depoimentos-carousel slider" data-arrows="true">
                    <Slider {...slickSettings2}>
                      <div className='depoimento-container row'>
                        <div className='corretor-image col-md-4'>
                          {/* <img src={FotoDepoimento} loading="lazy" alt="" className=""></img> */}
                          <div className='aspas'>
                            <img src={Aspas} loading="lazy" alt="" className=""></img>
                          </div>
                        </div>
                        <div className='texts-container col-md-8'>
                          <div className='texts'>
                            <div className='purple-text'>
                              "Excelente palestra,
                            </div>
                            Parabéns Cofeci, Sebrae e Homer, espero participar por muitos e muitos anos deste encontro, obrigado e grande abaço a todos."

                            <br />
                            <br />

                            <div className='blue-name'>Geraldo Matos</div>
                            <div className=''>Corretor de imóveis</div>
                          </div>
                        </div>
                      </div>
                      <div className='depoimento-container row'>
                        <div className='corretor-image col-md-4'>
                          {/* <img src={FotoDepoimento} loading="lazy" alt="" className=""></img> */}
                          <div className='aspas'>
                            <img src={Aspas} loading="lazy" alt="" className=""></img>
                          </div>
                        </div>
                        <div className='texts-container col-md-8'>
                          <div className='texts'>
                            <div className='purple-text'>
                              "Que SHOW!
                            </div>
                            Poderia virar a noite assistindo essa imersão de conhecimento. PARABÉNS mestre!!!"

                            <br />
                            <br />

                            <div className='blue-name'>Leiva Hott</div>
                            <div className=''>Corretora de imóveis</div>
                          </div>
                        </div>
                      </div>
                      <div className='depoimento-container row'>
                        <div className='corretor-image col-md-4'>
                          {/* <img src={FotoDepoimento} loading="lazy" alt="" className=""></img> */}
                          <div className='aspas'>
                            <img src={Aspas} loading="lazy" alt="" className=""></img>
                          </div>
                        </div>
                        <div className='texts-container col-md-8'>
                          <div className='texts'>
                            <div className='purple-text'>
                              "Parabéns pela parceria SEBRAE, COFECI e CRECI.
                            </div>
                            Os corretores de imóveis precisam se capacitar sempre."

                            <br />
                            <br />

                            <div className='blue-name'>João Batista Fernandes</div>
                            <div className=''>Corretor de imóveis</div>
                          </div>
                        </div>
                      </div>
                      <div className='depoimento-container row'>
                        <div className='corretor-image col-md-4'>
                          {/* <img src={FotoDepoimento} loading="lazy" alt="" className=""></img> */}
                          <div className='aspas'>
                            <img src={Aspas} loading="lazy" alt="" className=""></img>
                          </div>
                        </div>
                        <div className='texts-container col-md-8'>
                          <div className='texts'>
                            <div className='purple-text'>
                              "Excelente palestra
                            </div>
                            para atualizar a classe e a renovação de identidade do Corretor de Imóveis."

                            <br />
                            <br />

                            <div className='blue-name'>Ueliton Mensagens</div>
                            <div className=''>Corretor de imóveis</div>
                          </div>
                        </div>
                      </div>
                      <div className='depoimento-container row'>
                        <div className='corretor-image col-md-4'>
                          {/* <img src={FotoDepoimento} loading="lazy" alt="" className=""></img> */}
                          <div className='aspas'>
                            <img src={Aspas} loading="lazy" alt="" className=""></img>
                          </div>
                        </div>
                        <div className='texts-container col-md-8'>
                          <div className='texts'>
                            <div className='purple-text'>
                              "Excelente palestra,
                            </div>
                            está agregando muito para expandir meus conhecimentos e colocá-los em prática!"

                            <br />
                            <br />

                            <div className='blue-name'>Mirian Biithner</div>
                            <div className=''>Corretora de imóveis</div>
                          </div>
                        </div>
                      </div>
                    </Slider>
                  </section>
                </div>
              </div>
            </div>

            <div id="parceiros" className="section-3">
              <h3 className="heading text-block2">
                Parceiros
              </h3>
              <div className="row" align="center">
                <div className="container containerLogoCreci" >
                  <section className="logo-carousel slider" data-arrows="true">
                    <Slider {...slickSettings}>
                      <div>
                        <img src={LogoCreciAc} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciAl} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciAm} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciAP} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciBa} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciCe} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciDf} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciEs} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciGo} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciMa} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciMg} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciMs} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciMt} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciPa} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciPb} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciPe} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciPi} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciPr} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciRj} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciRn} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciRo} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciRr} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciRs} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciSc} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciSe} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciSp} loading="lazy" alt="" className=""></img>
                      </div>
                      <div>
                        <img src={LogoCreciTo} loading="lazy" alt="" className=""></img>
                      </div>
                    </Slider>
                  </section>
                </div>
              </div>
            </div>
          </>
        )}

        <div className='img-hugging'>
          <img src={HuggingPeople} loading="lazy" alt="" className=""></img>
        </div>
        <div className="section-4" >
          <div className="container-7 w-container">
            <div className="w-row" align="center">
              <div className="w-clearfix w-col w-col-5">
                <div>
                  <div className="text-block-6">
                    Realização:
                    <img src={LogoCofeci} loading="lazy" alt="" className="image-3 creci"></img>
                    <img src={LogoSebrae} loading="lazy" alt="" className="image-3 creci"></img>
                  </div>
                </div>
              </div>
              {/* <div className="w-col div-separator"></div> */}
              <div className="w-col w-col-6">
                <div className="w-clearfix">
                  <div className="text-block-6">Apoio:
                    <img src={LogoIGlobal} loading="lazy" alt="" className="image-3 creci"></img>
                    {/* <img src={LogoIbrep} loading="lazy" alt="" className=""></img> */}
                    <img src={LogoHomer2} loading="lazy" alt="" className="image-3 creci"></img>
                    <img src={LogoCimi} loading="lazy" alt="" className="image-3 creci"></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-image">

        </div>
        <div className="section-5">
          <div className="w-container">
            <div className="text-block-5">Powered by Homer</div>
          </div>
        </div>
        {/* <div id="myModal" className="modal">
          <div className="modal-content">
            <span className="close">&times;</span>
            <div className="text-block-7">Insira seu e-mail e senha:</div>
            <div className="w-form">
              <form id="login-form" action="#" method="POST" name="email-form">
                <label for="Email" className="field-label">E-mail</label>
                <input type="email" className="text-field-2 w-input" maxlength="256" name="login-email" id="login-email" placeholder="Insira seu e-mail" required=""></input>
                <label for="Password" className="field-label-2">Senha</label>
                <input type="password" className="text-field w-input" maxlength="256" name="login-pass" id="login-pass" placeholder="Insira sua senha" required=""></input>
                <a href="recuperar-senha.php">Esqueceu sua senha?</a>
                <br />
                <button className="submit-button" id="login-button" name="login-button">ENTRAR</button>
              </form>
            </div>
          </div>
        </div> */}
      </body >
    </>
  )
};