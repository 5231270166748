import styled from "styled-components";

export default styled.button`
  background: #514990;
  color: #FFF;
  box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
  height: 52px;
  border: 2px solid #FFF;
  border-radius: 4px;
  outline: none;
  padding: 0 16px;
  font-size: 16px;
  transition: background all 0.2s ease-in;
  cursor: pointer;
  margin-bottom: 8px;

&.red{
  background: #fc5050;
}

  &[disabled]{
    background: #CCC;
    border: 2px solid #CCC;
    cursor: default;
  }
`;