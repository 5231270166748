import LoginForm from "../../components/LoginForm";
import {
  Container,
  // Header
} from './styles';
export default function Login() {
  return (
    <>
      <Container>
        {/* <Header>
          <strong>
            Entrar
          </strong>
        </Header> */}
      </Container>

      <LoginForm
      />
    </>
  )
};