import styled from "styled-components";

export const Card = styled.div`
  background: #FFF;
  box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
  padding: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + &{
    margin-top: 16px;
  }
  
  .info {
    .appointment-name {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      font-size: 20px;

      small {
        background: ${({ theme }) => theme.colors.primary.lighter};
        color: ${({ theme }) => theme.colors.primary.main};
        text-transform: uppercase;
        padding: 4px;
        border-radius: 4px;
        margin-left: 8px;
      }
    }

    .span{
      display: block;
      font-size: 14px;
      color: gray;
      margin-bottom: 8px;
    }
  }

  .actions{ 
      display: flex;
      align-items: center;
      justify-content: space-around;

      button{
        background: transparent;
        border: none;
        margin-left: 8px;
      }
    }
`;

export const ListContainer = styled.div`
  margin-top: 24px;

  header{
    margin-bottom: 8px;

    .sort-button{
      background: transparent;
      border: none;
      display: flex;
      align-items: center;

      span {
        margin-right: 8px;
        font-weight: bold;
        color: gray;

        img{
          height: 8px;
        }
      }
    }
  }

  justify-content: left;
  display: flex;
  flex-direction: column;

  img{
    height: 74px;
  }
`;

export const Container = styled.div`
  margin: auto;
  margin-top: 32px;
  text-align: center;
`;

export const ErrorContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #222;

  strong{
    color: #fc5050;
  }
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  margin-bottom: 24px;

  strong{
    color: #222;
    font-size: 32px;
  }
`;

export const Form = styled.form`
  div{
    margin-bottom: 8px;
    font-weight: bold;
    color: gray;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 24px;

  button{
    width: 100%;
    cursor: pointer;
  }
`;