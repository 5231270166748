import styled, { css } from "styled-components";

export default styled.input`  
  width: 100%;
  background: #FFF;
  box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
  height: 52px;
  border: 2px solid #FFF;
  border-radius: 4px;
  outline: none;
  padding: 0 16px;
  font-size: 16px;
  transition: border-color 0.2s ease-in;
  appearance: none;

  &:focus{
    border: 2px solid #514990;
  }

  ${({ theme, error }) => error && css`
    color: #fc5050;
    border-color: red !important;

    &:focus{
      color: #fc5050;
      border-color: red !important;
    }

  `};

  &.react-date-picker__inputGroup__input{
    width: 100%;
    background: #FFF;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
    height: 52px;
    border: 2px solid #FFF;
    border-radius: 4px;
    outline: none;
    padding: 0 16px;
    font-size: 16px;
    transition: border-color 0.2s ease-in;
    appearance: none;

    &:focus{
      border: 2px solid #514990;
    }

    ${({ theme, error }) => error && css`
      color: #514990;
      border-color: #514990 !important;
      

      &:focus{
        color: #fc5050;
        border-color: #514990 !important;
      }

    `};
  }

  &.document-value{
    width: 100%;
    background: #FFF;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.04);
    height: 52px;
    border: 2px solid #FFF;
    border-radius: 4px;
    outline: none;
    padding: 0 16px;
    font-size: 16px;
    transition: border-color 0.2s ease-in;
    appearance: none;

    &:focus{
      border: 2px solid #514990;
    }
  }
`;
