/* eslint-disable react-hooks/exhaustive-deps */
// import StatesService from '../../services/StatesService'
// import CitiesService from '../../services/CitiesService'
import LeadsService from '../../services/LeadsService'
import Config from '../../config/environments/local';
// import FormGroup from '../../components/FormGroup'
// import { localGet } from "../../lib/session";
// import {
//   localGet,
//   // localRemove
// } from "../../lib/session";

// import Modal from '../../components/Modal'
// import Busca from '../../assets/icons/search.svg'
// import Calendar from '../../assets/icons/calendar_month.svg'

// import Teodoro from '../../assets/images/landing-page/palestrantes/2024/joao-teodoro.svg'
// import Clock from '../../assets/icons/clock.svg'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import Loader from '../../components/Loader'
import {
  useState,
  // useEffect,
  useCallback,
} from "react";
// import UsersService from '../../services/UsersService'

export default function AdminImages() {

  // const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  const [isLoading, setIsLoading] = useState(false);
  // const [isModalSucessOpen, setIsModalSucessOpen] = useState(false);
  // const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);

  // const [name, setName] = useState('');

  const [images, setImages] = useState({});
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const loadImages = useCallback(async () => {
    try {
      setIsLoading(true);
      const imagesList = await new LeadsService(Config.API_BASE_URL).GetImages();
      setImages(imagesList.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [setImages]);

  if (isFirstLoad) {
    loadImages();
    setIsFirstLoad(false);
  }

  function handleChangeFiles1(e) {
    if (e.target.files[0]) {
      uploadData(1, e.target.files[0]);
    }
  }
  function handleChangeFiles2(e) {
    if (e.target.files[0]) {
      uploadData(2, e.target.files[0]);
    }
  }
  function handleChangeFiles3(e) {
    if (e.target.files[0]) {
      uploadData(3, e.target.files[0]);
    }
  }
  function handleChangeFiles4(e) {
    if (e.target.files[0]) {
      uploadData(4, e.target.files[0]);
    }
  }
  function handleChangeFiles5(e) {
    if (e.target.files[0]) {
      uploadData(5, e.target.files[0]);
    }
  }
  function handleChangeFiles6(e) {
    if (e.target.files[0]) {
      uploadData(6, e.target.files[0]);
    }
  }
  function handleChangeFiles7(e) {
    if (e.target.files[0]) {
      uploadData(7, e.target.files[0]);
    }
  }
  function handleChangeFiles8(e) {
    if (e.target.files[0]) {
      uploadData(8, e.target.files[0]);
    }
  }
  function handleChangeFiles9(e) {
    if (e.target.files[0]) {
      uploadData(9, e.target.files[0]);
    }
  }
  function handleChangeFiles10(e) {
    if (e.target.files[0]) {
      uploadData(10, e.target.files[0]);
    }
  }

  // function handleChangeName(e) {
  //   setName(e.target.value);

  //   loadImages();
  // }

  // function handleSucessModal() {
  //   window.open('/', '_self');
  // }

  // function hadleCloseModal() {
  //   setIsModalErrorOpen(false);
  //   setIsModalSucessOpen(false);
  // }

  async function uploadData(imagePosition, files) {

    const formDataToSend = new FormData();

    // formDataToSend.append('UserId', sessionObject.userId);
    formDataToSend.append('ImagePosition', imagePosition);
    formDataToSend.append('Files', files);
    setIsLoading(true);
    await new LeadsService(Config.API_BASE_URL).AddUpdateImages(formDataToSend);
    setIsLoading(false);
    window.open('/admin-images', '_self');
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      {/* <Modal
        title='Cadastro realizado com sucesso'
        body='Enviamos uma confirmação para você por e-mail'
        isVisible={isModalSucessOpen}
        onConfirm={handleSucessModal}
      // onCancel={hadleCloseModal}
      />
      <Modal
        title='Ocorreu um erro ao cadastrar'
        body='Caso já tenha se cadastrado com este e-mail, verifique a sua caixa de entrada e localize nosso e-mail de confirmação.'
        danger
        isVisible={isModalErrorOpen}
        onConfirm={hadleCloseModal}
      // onCancel={hadleCloseModal}
      /> */}
      <div className="body">
        <div id="admin-container">
          <div className="">
            {(images) && (
              <div className='gallery-container'>
                <div className='row d-flex justify-content-center'>
                  <div className='col-lg-2 col-md-6'>
                    <div className='gallery-item'>
                      {(images.FileUrl1) && (
                        <Zoom>
                          <img src={images.FileUrl1} alt="" />
                        </Zoom>
                      )}
                      <br />
                      <br />
                      1-
                      <input
                        id='appointment-file'
                        className="inputFile custom-file-input"
                        type="file"
                        name="appointmentFiles"
                        onChange={(e) => { handleChangeFiles1(e) }}
                      />
                    </div>
                  </div>
                  <div className='col-lg-2 col-md-6'>
                    <div className='gallery-item'>
                      {(images.FileUrl2) && (
                        <Zoom>
                          <img src={images.FileUrl2} alt="" />
                        </Zoom>
                      )}
                      <br />
                      <br />
                      2-
                      <input
                        id='appointment-file'
                        className="inputFile custom-file-input"
                        type="file"
                        name="appointmentFiles"
                        onChange={(e) => { handleChangeFiles2(e) }}
                      />
                    </div>
                  </div>
                  <div className='col-lg-2 col-md-6'>
                    <div className='gallery-item'>
                      {(images.FileUrl3) && (
                        <Zoom>
                          <img src={images.FileUrl3} alt="" />
                        </Zoom>
                      )}
                      <br />
                      <br />
                      3-
                      <input
                        id='appointment-file'
                        className="inputFile custom-file-input"
                        type="file"
                        name="appointmentFiles"
                        onChange={(e) => { handleChangeFiles3(e) }}
                      />
                    </div>
                  </div>
                  <div className='col-lg-2 col-md-6'>
                    <div className='gallery-item'>
                      {(images.FileUrl4) && (
                        <Zoom>
                          <img src={images.FileUrl4} alt="" />
                        </Zoom>
                      )}
                      <br />
                      <br />
                      4-
                      <input
                        id='appointment-file'
                        className="inputFile custom-file-input"
                        type="file"
                        name="appointmentFiles"
                        onChange={(e) => { handleChangeFiles4(e) }}
                      />
                    </div>
                  </div>
                  <div className='col-lg-2 col-md-6'>
                    <div className='gallery-item'>
                      {(images.FileUrl5) && (
                        <Zoom>
                          <img src={images.FileUrl5} alt="" />
                        </Zoom>
                      )}
                      <br />
                      <br />
                      5-
                      <input
                        id='appointment-file'
                        className="inputFile custom-file-input"
                        type="file"
                        name="appointmentFiles"
                        onChange={(e) => { handleChangeFiles5(e) }}
                      />
                    </div>
                  </div>
                </div>
                <div className='row d-flex justify-content-center'>
                  <div className='col-lg-2 col-md-6'>
                    <div className='gallery-item'>
                      {(images.FileUrl6) && (
                        <Zoom>
                          <img src={images.FileUrl6} alt="" />
                        </Zoom>
                      )}
                      <br />
                      <br />
                      6-
                      <input
                        id='appointment-file'
                        className="inputFile custom-file-input"
                        type="file"
                        name="appointmentFiles"
                        onChange={(e) => { handleChangeFiles6(e) }}
                      />
                    </div>
                  </div>
                  <div className='col-lg-2 col-md-6'>
                    <div className='gallery-item'>
                      {(images.FileUrl7) && (
                        <Zoom>
                          <img src={images.FileUrl7} alt="" />
                        </Zoom>
                      )}
                      <br />
                      <br />
                      7-
                      <input
                        id='appointment-file'
                        className="inputFile custom-file-input"
                        type="file"
                        name="appointmentFiles"
                        onChange={(e) => { handleChangeFiles7(e) }}
                      />
                    </div>
                  </div>
                  <div className='col-lg-2 col-md-6'>
                    <div className='gallery-item'>
                      {(images.FileUrl8) && (
                        <Zoom>
                          <img src={images.FileUrl8} alt="" />
                        </Zoom>
                      )}
                      <br />
                      <br />
                      8-
                      <input
                        id='appointment-file'
                        className="inputFile custom-file-input"
                        type="file"
                        name="appointmentFiles"
                        onChange={(e) => { handleChangeFiles8(e) }}
                      />
                    </div>
                  </div>
                  <div className='col-lg-2 col-md-6'>
                    <div className='gallery-item'>
                      {(images.FileUrl9) && (
                        <Zoom>
                          <img src={images.FileUrl9} alt="" />
                        </Zoom>
                      )}
                      <br />
                      <br />
                      9-
                      <input
                        id='appointment-file'
                        className="inputFile custom-file-input"
                        type="file"
                        name="appointmentFiles"
                        onChange={(e) => { handleChangeFiles9(e) }}
                      />
                    </div>
                  </div>
                  <div className='col-lg-2 col-md-6'>
                    <div className='gallery-item'>
                      {(images.FileUrl10) && (
                        <Zoom>
                          <img src={images.FileUrl10} alt="" />
                        </Zoom>
                      )}
                      <br />
                      <br />
                      10-
                      <input
                        id='appointment-file'
                        className="inputFile custom-file-input"
                        type="file"
                        name="appointmentFiles"
                        onChange={(e) => { handleChangeFiles10(e) }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

      </div >
    </>
  )
};