/* eslint-disable jsx-a11y/alt-text */
import Confirmation from '../../assets/images/landing-page/confirmacao_inscricao_saber.png'

export default function Mail() {

  return (
    <>
      <img
        src={Confirmation}
        style={{
          height: "100%",
          width: "100%",
        }}
      >
      </img>
    </>
  )
};