import { Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Mail from './pages/mail';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Admin from './pages/Admin';
import AdminImages from './pages/AdminImages';

import Sobre from './pages/Sobre';
import Programacao from './pages/Programacao';
import Depoimentos from './pages/Depoimentos';
import Blog from './pages/Blog';
// import Test from './pages/Test';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/mail/confirmacao_inscricao_saber.png" component={Mail} />
      <Route exact path="/recuperar-senha" component={ForgotPassword} />
      <Route exact path="/sobre" component={Sobre} />
      <Route exact path="/programacao" component={Programacao} />
      <Route exact path="/depoimentos" component={Depoimentos} />
      <Route exact path="/blog" component={Blog} />
      <Route exact path="/admin" component={Admin} />
      <Route exact path="/admin-images" component={AdminImages} />
      {/* <Route exact path="/test" component={Test} /> */}
      {/* <Route exact path="/mail/confirmacao_inscricao_saber.png" component={Confirmation}/> */}
      {/*
      <Route exact path="/reset-password/:code" component={ResetPassword}/>
      <Route exact path="/change-password" component={ChangePassword}/>
      <Route exact path="/shared" component={Shared}/>
      <Route exact path="/shared/:id" component={SharedAppointment}/>
      <Route exact path="/active" component={Home}/>
      <Route exact path="/all" component={AllAppointments}/>
      <Route exact path="/appointments/:viewerUserId" component={Appointments}/>
      <Route exact path="/login" component={Login}/>
      <Route exact path="/new/:appointmentCategoryId" component={NewAppointment}/> */}
      {/* <Route exact path="/edit/:id" component={EditAppointment}/> */}
      {/* <Route exact path="/edit/:id" component={EditAppointment}/>
      <Route exact path="/" component={Home}/>
      <Route exact path="/users/new" component={NewUser}/>
      <Route exact path="/users/edit/:id" component={EditUser}/>
      <Route exact path="/users/confirm/:code" component={UserConfirmation}/>
      <Route exact path="/appointment-types" component={AppointmentTypes}/>
      <Route exact path="/appointment-types/edit/:id" component={EditAppointmentType}/>
      <Route exact path="/appointment-types/new/:appointmentCategoryId" component={NewAppointmentType}/> */}
    </Switch>
  );
}