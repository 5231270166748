import HttpClient from './utils/HttpClient';

class LeadsService {
  constructor(baseUrl) {

    this.baseUrl = baseUrl;

    this.httpClient = new HttpClient(this.baseUrl)
  }

  async login(userData) {
    const urlPath = '/leads/login';

    return this.httpClient.post(urlPath, userData);
  }

  async ResetPassword(userData) {
    const urlPath = '/leads/reset-password';

    return this.httpClient.post(urlPath, userData);
  }

  async AddUpdateImages(appointment) {
    const urlPath = '/leads/add-update-images';

    return this.httpClient.postFormData(urlPath, appointment);
  }

  async UpdatePassword(userData) {
    const urlPath = '/leads/update-password';

    return this.httpClient.post(urlPath, userData);
  }

  async Create(userData) {
    const urlPath = '/leads';

    return this.httpClient.post(urlPath, userData);
  }

  async Update(userData) {
    const urlPath = '/leads/update';

    return this.httpClient.post(urlPath, userData);
  }

  async ActivateUser(code) {
    const urlPath = '/leads/activate-user';

    return this.httpClient.post(urlPath, code);
  }

  async GetUserByCode(code) {
    const urlPath = '/leads/get-by-code';

    return this.httpClient.post(urlPath, code);
  }

  async GetById(userId) {
    const urlPath = `/leads/get/${userId}`;

    return this.httpClient.get(urlPath);
  }

  async GetAll() {
    const urlPath = `/leads/get-all`;

    return this.httpClient.get(urlPath);
  }

  async GetImages() {
    const urlPath = `/leads/get-images`;

    return this.httpClient.get(urlPath);
  }

  async Filter(data) {
    const urlPath = '/leads/filter';

    return this.httpClient.post(urlPath, data);
  }

  async GetMustShowDownloadMaterialButton() {
    const urlPath = `/leads/getmustshowdownloadmaterialbutton`;

    return this.httpClient.get(urlPath);
  }
}

export default LeadsService;