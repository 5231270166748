const Config = {
  // API_BASE_URL: 'https://homol.saberimobiliario.service.kroonar.com/api',
  API_BASE_URL: 'https://saberimobiliario.service.kroonar.com/api',
  // API_BASE_URL: 'http://localhost:56320/api',
  KROONAR_PRINT_URL: 'https://print.kroonar.com/Print/saberimobiliario?UserId=',
  SESSION_KEY: 'saberimobiliario_session',
  EXPIRATION_AUTH: process.env.MAX_IDLE_TIME || 2.592e+9,
  DEFAULT_USER_OBJECT: {
    id: 1,
    name: 'Teste Homer',
    password: '123456'
  }
};

export default Config;