import HttpClient from './utils/HttpClient';

class UsersService {
  constructor(baseUrl) {

    this.baseUrl = baseUrl;

    this.httpClient = new HttpClient(this.baseUrl)
  }

  async login(userData) {
    const urlPath = '/leads/login';

    return this.httpClient.post(urlPath, userData);
  }

  async AddLeadWatchedContent(userData) {
    const urlPath = '/leads/add-lead-watched-content';

    return this.httpClient.post(urlPath, userData);
  }

  async ResetPassword(userData) {
    const urlPath = '/leads/reset-password';

    return this.httpClient.post(urlPath, userData);
  }

  async UpdatePassword(userData) {
    const urlPath = '/leads/update-password';

    return this.httpClient.post(urlPath, userData);
  }

  async Create(userData) {
    const urlPath = '/leads';

    return this.httpClient.post(urlPath, userData);
  }

  async Update(userData) {
    const urlPath = '/leads/update';

    return this.httpClient.post(urlPath, userData);
  }

  async ActivateUser(code) {
    const urlPath = '/leads/activate-user';

    return this.httpClient.post(urlPath, code);
  }

  async GetUserByCode(code) {
    const urlPath = '/leads/get-by-code';

    return this.httpClient.post(urlPath, code);
  }

  async GetById(userId) {
    const urlPath = `/leads/get/${userId}`;

    return this.httpClient.get(urlPath);
  }

  async GetAll() {
    const urlPath = `/leads/get-all`;

    return this.httpClient.get(urlPath);
  }
}

export default UsersService;