/* eslint-disable react-hooks/exhaustive-deps */
// import StatesService from '../../services/StatesService'
// import CitiesService from '../../services/CitiesService'
import LeadsService from '../../services/LeadsService'
import Config from '../../config/environments/local';
import FormGroup from '../../components/FormGroup'
// import {
//   localGet,
//   // localRemove
// } from "../../lib/session";

import Loader from '../../components/Loader'
import Modal from '../../components/Modal'
import Busca from '../../assets/icons/search.svg'
import Calendar from '../../assets/icons/calendar_month.svg'
import StatesService from '../../services/StatesService'

// import Teodoro from '../../assets/images/landing-page/palestrantes/2024/joao-teodoro.svg'
// import Clock from '../../assets/icons/clock.svg'

import {
  useState,
  useEffect,
  useCallback,
} from "react";
// import UsersService from '../../services/UsersService'

export default function Admin() {

  const [isLoading, setIsLoading] = useState(false);
  const [isModalSucessOpen, setIsModalSucessOpen] = useState(false);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);

  const [leads, setLeads] = useState([]);
  const [leadsDay, setLeadsDay] = useState([]);
  const [states, setStates] = useState([]);

  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [dateDate, setDateDate] = useState(new Date());
  const [state, setState] = useState('');

  const [activeTabPane, setActiveTabPane] = useState(1);

  // const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  async function handleSubmit() {
    if (activeTabPane === 1) {
      await loadLeads();
    }
    else {
      await loadLeadsDay();
    }
  }

  const loadCitiesByStateId = useCallback(async () => {
    try {
      // setIsLoading(true);
      const leadsList = await new LeadsService(Config.API_BASE_URL).Filter({
        Text: name,
        StateId: state,
        Date: null,
      });
      setLeads(leadsList.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      // setIsLoading(false);
    }
  }, [state]);

  const loadLeads = useCallback(async () => {
    try {
      setIsLoading(true);
      const leadsList = await new LeadsService(Config.API_BASE_URL).Filter({
        Text: name,
        StateId: state,
        Date: null,
      });
      setLeads(leadsList.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [name, setLeads, state]);

  const loadLeadsDay = useCallback(async () => {
    try {
      setIsLoading(true);
      const leadsDayList = await new LeadsService(Config.API_BASE_URL).Filter({
        Text: name,
        StateId: state,
        Date: dateDate,
      });
      setLeadsDay(leadsDayList.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [dateDate, name, setLeadsDay, state]);

  useEffect(() => {
    async function loadStates() {
      try {
        const statesList = await new StatesService(Config.API_BASE_URL).getAll();
        setStates(statesList.Result);
      } catch (error) {
        console.log('error ', error);
      } finally {
      }
    }


    if (isFirstLoad) {
      loadStates();
      // loadLeads();
      // loadLeadsDay();
      setIsFirstLoad(false);
      handleSubmit();
    }
  }, [loadLeads, loadLeadsDay, loadCitiesByStateId]);

  function handleChangeName(e) {
    setName(e.target.value);

    // loadLeads();
    // loadLeadsDay();
  }

  function handleChangeState(e) {
    setState(e.target.value);
    console.log(e.target.value);
    console.log(state);

    // loadLeads();
    // loadLeadsDay();
  }

  function handleDateOnKeyPress(e) {
    let input = e.target;

    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }

    var len = input.value.length;

    // if (len === 0) {
    //   e.preventDefault();
    // }

    // If we're at a particular place, let the user type the slash
    if (len !== 1 || len !== 3) {
      if (e.charCode === 47) {
        e.preventDefault();
      }
    }

    if (e.key !== 'Backspace') {
      // If they don't add the slash, do it for them...
      if (len === 2) {
        input.value += '/';
      }

      // If they don't add the slash, do it for them...
      if (len === 5) {
        input.value += '/';
      }
    }
  }

  function handleChangeDate(e) {
    const inputValue = e.target.value;
    setDate(e.target.value);

    if (e.target.value && e.target.value.length === 10) {
      try {
        const [day, month, year] = inputValue.split('/');
        const date = new Date(+year, month - 1, +day);
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        if (date) {
          setDateDate(date);
          // loadLeadsDay();
        }
        else {
          setDateDate(null);
        }
      }
      catch (err) {
        return false;
      }
    }

  }

  function handleSucessModal() {
    window.open('/', '_self');
  }

  function hadleCloseModal() {
    setIsModalErrorOpen(false);
    setIsModalSucessOpen(false);
  }

  let rowType = 'odd';

  return (
    <>
      <Loader isLoading={isLoading} />
      <Modal
        title='Cadastro realizado com sucesso'
        body='Enviamos uma confirmação para você por e-mail'
        isVisible={isModalSucessOpen}
        onConfirm={handleSucessModal}
      // onCancel={hadleCloseModal}
      />
      <Modal
        title='Ocorreu um erro ao cadastrar'
        body='Caso já tenha se cadastrado com este e-mail, verifique a sua caixa de entrada e localize nosso e-mail de confirmação.'
        danger
        isVisible={isModalErrorOpen}
        onConfirm={hadleCloseModal}
      // onCancel={hadleCloseModal}
      />
      <div className="body">
        <div id="admin-container">
          <div className="container">
            <div className="w-container-section-2 admin div-table">
              <div className="div-table-body">
                <div
                  className="div-table-row admin"
                >
                  <div className="div-table-cell admin"
                    style={{
                      marginRight: '24px'
                    }}
                  >
                    <FormGroup className={`formgroup-search`}>
                      <img src={Busca} loading="lazy" alt="busca"></img>
                      <input
                        type="text"
                        className="css-input name-user"
                        name="name-user"
                        id="name-user"
                        placeholder="Pesquisar"
                        required
                        value={name}
                        onChange={handleChangeName}
                      />
                    </FormGroup>
                  </div>
                  <div className="div-table-cell admin">
                    <FormGroup className={`formgroup-search`}>
                      <select
                        id="state-user"
                        name="state-user"
                        className="css-input state-user"
                        placeholder="UF"
                        required
                        value={state}
                        onChange={handleChangeState}
                      >
                        <option value="">UF</option>
                        {states && (
                          states.map((state) => (
                            <option key={state.Id} value={state.Id}>{state.Acronym}</option>
                          ))
                        )}
                      </select>
                    </FormGroup>
                  </div>
                  <div className="div-table-cell admin">
                    <div className='button-container admin'
                      style={{
                        gap: '8px',
                        display: 'flex',
                        justifyContent: 'flex-start'
                      }}
                    >
                      <button
                        className="button w-button"
                        onClick={handleSubmit}
                      >
                        Filtrar resultados
                      </button>
                      <a href="https://saberimobiliario.service.kroonar.com/api/leads/export" target='_blank' className="button w-button" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <mask id="mask0_37_2417" style={{
                            maskType: "alpha"
                          }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                            <rect width="24" height="24" fill="#D9D9D9" />
                          </mask>
                          <g mask="url(#mask0_37_2417)">
                            <path d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6Z" fill="white" />
                          </g>
                        </svg>
                        Download</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="big-text">Listagem de leads</div>
            <div data-duration-in="300" data-duration-out="100" className="tabs-2 w-tabs">
              <div className="w-tab-menu admin">
                <div className="tab-link-space-25">
                  <a href="#t" data-w-tab="25"
                    className={`w-inline-block w-tab-link ${(activeTabPane === 1 ? "w--current" : "")}`}>
                    <div
                      className="text-block-11"
                      onClick={() => { setActiveTabPane(1); }}
                    >
                      Geral
                    </div>
                  </a>
                </div>
                <div className="tab-link-space-29">
                  <a href="#t" data-w-tab="26"
                    className={`tab-link-01-12 w-inline-block w-tab-link ${(activeTabPane === 2 ? "w--current" : "")}`}>
                    <div
                      className="text-block-11"
                      onClick={() => { setActiveTabPane(2); }}
                    >
                      Por Dia
                    </div>
                  </a>
                </div>
              </div>
              <div className="w-tab-content admin admin-tab-content">
                {(activeTabPane === 1) && (
                  <div data-w-tab="25" className="tab-pane-tab-1-2 w-tab-pane w--tab-active admin">
                    <div className="columns w-row">
                      <table className='admin-table'>
                        <thead>
                          <tr>
                            <th>Data de Cadastro</th>
                            <th>Id</th>
                            <th>Nome</th>
                            <th>CPF</th>
                            <th>CNPJ</th>
                            <th>CRECI</th>
                            <th>E-mail</th>
                            <th>Cargo/Função</th>
                            <th>Nome da Empresa</th>
                            <th>Estado</th>
                            <th>Cidade</th>
                            <th>Telefone</th>
                            <th>Corretor ou Estagiário?</th>
                          </tr>
                        </thead>
                        <tbody>
                          {leads && (
                            leads.map((lead) => (
                              // <option key={appointmentRecurrenceType.Id} value={appointmentRecurrenceType.Id}>{appointmentRecurrenceType.Name}</option>
                              <tr key={`row-${lead.Id}`} className={`${rowType === 'odd' ? rowType = 'even' : rowType = 'odd'}`}>
                                <td key={lead.Id}>{lead.InsertDateString}</td>
                                <td key={lead.Id}>{lead.Id}</td>
                                <td key={lead.Id}>{lead.Name}</td>
                                <td key={lead.Id}>{lead.Cpf}</td>
                                <td key={lead.Id}>{lead.Cnpj}</td>
                                <td key={lead.Id}>{lead.Creci}</td>
                                <td key={lead.Id}>{lead.Email}</td>
                                <td key={lead.Id}>{lead.JobDescription}</td>
                                <td key={lead.Id}>{lead.CompanyName}</td>
                                <td key={lead.Id}>{lead.StateName}</td>
                                <td key={lead.Id}>{lead.CityName}</td>
                                <td key={lead.Id}>{lead.PhoneNumber}</td>
                                <td key={lead.Id}>{(lead.RealtorType === '1' ? 'Corretor' : 'Estagiário')}</td>
                              </tr>
                            )))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {(activeTabPane === 2) && (
                  <div data-w-tab="26" className="tab-pane-tab-1-2 w-tab-pane w--tab-active admin">
                    <div className="columns w-row">
                      <div className="w-container-section-2 admin div-table">
                        <div className="div-table-body">
                          <div className="div-table-row admin">
                            <div className="div-table-cell">
                              <FormGroup className={`formgroup-inline`}>
                                <img src={Calendar} loading="lazy" alt="busca"></img>
                                <div className='label'>
                                  Selecione o dia
                                </div>
                                <input
                                  type="text"
                                  className="css-input"
                                  name="name-user"
                                  id="name-user"
                                  placeholder='__/__/____'
                                  required
                                  value={date}
                                  onChange={handleChangeDate}
                                  onKeyPress={handleDateOnKeyPress}
                                  onKeyUp={handleDateOnKeyPress}
                                />
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                      </div>
                      <table className='admin-table'>
                        <thead>
                          <tr>
                            <th>Data de Cadastro</th>
                            <th>Id</th>
                            <th>Nome</th>
                            <th>CPF</th>
                            <th>CNPJ</th>
                            <th>CRECI</th>
                            <th>E-mail</th>
                            <th>Cargo/Função</th>
                            <th>Nome da Empresa</th>
                            <th>Estado</th>
                            <th>Cidade</th>
                            <th>Telefone</th>
                            <th>Corretor ou Estagiário?</th>
                          </tr>
                        </thead>
                        <tbody>
                          {leadsDay && (
                            leadsDay.map((lead) => (
                              // <option key={appointmentRecurrenceType.Id} value={appointmentRecurrenceType.Id}>{appointmentRecurrenceType.Name}</option>
                              <tr key={`row-${lead.Id}`} className={`${rowType === 'odd' ? rowType = 'even' : rowType = 'odd'}`}>
                                <td key={lead.Id}>{lead.InsertDateString}</td>
                                <td key={lead.Id}>{lead.Id}</td>
                                <td key={lead.Id}>{lead.Name}</td>
                                <td key={lead.Id}>{lead.Cpf}</td>
                                <td key={lead.Id}>{lead.Cnpj}</td>
                                <td key={lead.Id}>{lead.Creci}</td>
                                <td key={lead.Id}>{lead.Email}</td>
                                <td key={lead.Id}>{lead.JobDescription}</td>
                                <td key={lead.Id}>{lead.CompanyName}</td>
                                <td key={lead.Id}>{lead.StateName}</td>
                                <td key={lead.Id}>{lead.CityName}</td>
                                <td key={lead.Id}>{lead.PhoneNumber}</td>
                                <td key={lead.Id}>{(lead.RealtorType === '1' ? 'Corretor' : 'Estagiário')}</td>
                              </tr>
                            )))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>

          <div className='container'>
            <div className='lead-total-count'>Total geral {leads.length} inscritos</div>
          </div>
        </div>

      </div >
    </>
  )
};