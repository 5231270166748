import { useState } from 'react';
import { Form, ButtonContainer } from './styles';
import FormGroup from '../FormGroup';
import Input from '../Input';
import Button from '../Button';
import useErrors from '../../hooks/useErrors';
import Loader from '../Loader';
import { localGet, localSet } from '../../lib/session';
import Config from '../../config/environments/local';
// import UsersService from '../../services/UsersService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faEyeSlash,
  // faDollarSign,
} from '@fortawesome/free-solid-svg-icons'

export default function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  const {
    // errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  const isFormValid = (email && password);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionObject, setSessionObject] = useState(localGet(Config.SESSION_KEY));

  if (sessionObject) {
    window.open('/', '_self');
  }

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);

    // const response = await new UsersService(Config.API_BASE_URL).login({
    //   Email: email,
    //   Password: password,
    // });

    if (email === 'admin.homer.com' && password === 'saberimob') {
      const sessionObject = {
        userId: 1,
        userName: 'admin',
        userEmail: 'admin.homer.com',
        accessProfile: 'admin'
      };

      localSet(Config.SESSION_KEY, sessionObject, Config.EXPIRATION_AUTH);
      setSessionObject(sessionObject);

      window.open('/admin', '_self');
    }
    // if (response.Error) {
    //   setError({ field: 'email', message: response.Error.Message });
    //   // setError({ field: 'password', message: response.Error.Message });
    //   setIsLoading(false);
    // } else {
    //   removeError('email');
    //   // removeError('password');
    //   const sessionObject = {
    //     userId: response.Result.Id,
    //     userName: response.Result.Name,
    //     userEmail: response.Result.Email,
    //   };
  }

  function handleChangeEmail(e) {
    setEmail(e.target.value);

    if (!e.target.value) {
      setError({ field: 'email', message: 'o e-mail é obrigatório' });
    } else {
      removeError('email');
    }
  }

  function handleChangePassword(e) {
    setPassword(e.target.value);

    if (!e.target.value) {
      setError({ field: 'password', message: 'a senha é obrigatória' });
    } else {
      removeError('password');
    }
  }

  return (

    <div className='login-form-container'>
      <div className='header'>
        <strong>
          Acesse o Saber Imobiliário
        </strong>
      </div>
      <Form onSubmit={handleSubmit} noValidate>
        <div>E-mail</div>
        <FormGroup
          error={getErrorMessageByFieldName('email')}
        >
          <Input
            type="text"
            value={email}
            placeholder="E-mail"
            onChange={handleChangeEmail}
            error={getErrorMessageByFieldName('email')}
          />
        </FormGroup>

        <div>Senha</div>
        <FormGroup
          error={getErrorMessageByFieldName('password')}
        >
          <div className="input-group-btn">
            <Input
              type={passwordType}
              value={password}
              placeholder="Senha"
              onChange={handleChangePassword}
              error={getErrorMessageByFieldName('password')}
            />
            <button type="button" className="btn" onClick={togglePassword}>
              {passwordType === "password" ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
            </button>
          </div>
        </FormGroup>

        {/* <FormGroup>
          <a
            href="/recuperar-senha"
          >
            Esqueci minha senha
          </a>
        </FormGroup> */}


        <Loader isLoading={isLoading} />

        <ButtonContainer>
          <Button
            type="button"
            disabled={!isFormValid}
            // onClick={() => loginWithRedirect()}
            onClick={(e) => handleSubmit(e)}

          >
            Entrar
          </Button>
          {/* <Button
          type="button"
          // onClick={() => loginWithRedirect()}
          onClick={(e) => handleGoToRegister(e)}

        >
          Não possui conta? Cadastre-se aqui
        </Button> */}
        </ButtonContainer>
        {/* <FormGroup>
        <a
          href="/users/new"
        >
          Não possui conta? Cadastre-se aqui
        </a>
      </FormGroup> */}
      </Form >
    </div>
  );
}
