import Config from '../../config/environments/local';
import {
  localGet,
  // localRemove
} from "../../lib/session";

// import Teodoro from '../../assets/images/landing-page/palestrantes/2024/joao-teodoro.svg'
import Palestrante1 from '../../assets/images/2024/palestrantes/palestrante1.png'
import Palestrante2 from '../../assets/images/2024/palestrantes/palestrante2.png'
import Palestrante3 from '../../assets/images/2024/palestrantes/palestrante3.png'

import Clock from '../../assets/icons/clock.svg'
import LogoCofeci from '../../assets/images/landing-page/logo_cofeci.png'
import LogoSebrae from '../../assets/images/landing-page/logo_sebrae2x.png'

import LogoHomer2 from '../../assets/images/landing-page/logo_homer2x.png'
// import LogoIbrep from '../../assets/images/landing-page/logoIbrep.png'
import LogoIGlobal from '../../assets/images/landing-page/logo_iglobal2x.png'
// import Slider from "react-slick";
import LeadsService from '../../services/LeadsService'

import {
  useState,
  useEffect,
} from "react";

export default function Home() {

  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isDownloadButton, setIsDownloadButton] = useState(false);
  // const [isDownloadButton] = useState(true);


  useEffect(() => {
    async function GetMustShowDownloadMaterialButton() {
      if (!isFirstLoad)
        return;

      try {
        // setIsLoading(true);
        const mustshowdownloadmaterialbuttonResponse = await new LeadsService(Config.API_BASE_URL).GetMustShowDownloadMaterialButton();
        setIsDownloadButton(mustshowdownloadmaterialbuttonResponse.Result);
      } catch (error) {
        console.log('error ', error);
      } finally {
        // setIsLoading(false);
      }
    }

    GetMustShowDownloadMaterialButton();
  }, [isFirstLoad]);

  if (isFirstLoad && sessionObject) {
    setIsFirstLoad(false);
  }

  // var slickSettings = {
  //   dots: false,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 3,
  //   initialSlide: 0,
  // };


  return (
    <>
      <body className="body">
        <div id="programacao">
          <div className="">
            <div className="programacao-big-text">Fique por dentro do que vai rolar na nova edição do Saber Imobiliário</div>
            <br />
            <br />
            <br />
            <br />
            <br />

            <div className="columns w-row programacao-block">
              <div className="w-col w-col-4">
                <div className="div-block-3">
                  <img src={Palestrante1} loading="lazy" sizes="(max-width: 479px) 90vw, (max-width: 767px) 85vw, 30vw" width="500"
                    alt="Palestrante"></img>
                </div>
              </div>
              <div className="w-col w-col-8">
                <div className="div-block-4">
                  <div className='programacao-big-title'>
                    Dia 1 (18/06)
                  </div>
                  <div className="text-block-15">
                    <img src={Clock} loading="lazy" alt="Clock"></img>
                    19h30 às 21h30
                  </div>
                  <h4 className="heading-3"><strong>A mentalidade do corretor de sucesso!</strong></h4>
                  <div className="text-block-2">
                    <div className="div-line-point">
                      Ricardo Martins é um destacado expoente no mercado imobiliário nacional. Suas redes sociais acumulam mais de 90 milhões de visualizações mensais. Até março deste ano, foi sócio da My Broker, empresa que movimentou mais de 3 bilhões de reais em vendas no ano passado. Reconhecido como o maior treinador de profissionais do setor imobiliário brasileiro, Ricardo já formou mais de 15 mil alunos. Atualmente, conta com mais de 3 milhões de seguidores em suas redes sociais.
                    </div>
                  </div>
                  <div className='button-container'>
                    {(isDownloadButton) ? (
                      <>
                        <a href="https://bit.ly/SaberV-Resumo-1" className="button w-button">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <mask id="mask0_37_2417" style={{
                              maskType: "alpha"
                            }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                              <rect width="24" height="24" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_37_2417)">
                              <path d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6Z" fill="white" />
                            </g>
                          </svg>
                          Download material</a>
                      </>
                    ) : (
                      <>
                        <a href="https://bit.ly/Dia1-SaberImobV" className="button w-button">
                          Assista a palestra</a>
                      </>
                    )}
                  </div>
                  <h5 className="palestrante-bio"><strong>Bio: Ricardo Martins</strong></h5>
                  <div className="text-block-2">
                    <div className="div-line-point">
                      Ricardo Martins discutirá os aspectos que transcendem as percepções comuns sobre a carreira de corretor de imóveis. Para além da imagem estereotipada que inclui vestimentas e bens materiais, ele abordará um dos elementos mais cruciais para o êxito nesta profissão: a mentalidade do corretor.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="columns w-row programacao-block gray">
              <div className="w-col w-col-4">
                <div className="div-block-3">
                  <img src={Palestrante2} loading="lazy" sizes="(max-width: 479px) 90vw, (max-width: 767px) 85vw, 30vw" width="500"
                    alt="Palestrante"></img>
                </div>
              </div>
              <div className="w-col w-col-8">
                <div className="div-block-4">
                  <div className='programacao-big-title'>
                    Dia 2 (19/06)
                  </div>
                  <div className="text-block-15">
                    <img src={Clock} loading="lazy" alt="Clock"></img>
                    19h30 às 21h30
                  </div>
                  <h4 className="heading-3"><strong>
                    Mercado Internacional: Transforme Desafios em Oportunidades
                  </strong></h4>
                  <div className="text-block-2">
                    <div className="div-line-point">
                      Nesta palestra, Marta Faustino ensinará os 8 tópicos essenciais para o sucesso do corretor internacional. Com 19 anos de experiência no mercado americano, ela compartilhará estratégias práticas, destacando a importância da mudança de mindset. Marta apresentará cases de sucesso e insights valiosos para aumentar os ganhos e dolarizar o patrimônio dos clientes.
                    </div>
                  </div>
                  <div className='button-container'>
                    {(isDownloadButton) ? (
                      <>
                        <a href="https://bit.ly/SaberV-Resumo-2" className="button w-button">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <mask id="mask0_37_2417" style={{
                              maskType: "alpha"
                            }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                              <rect width="24" height="24" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_37_2417)">
                              <path d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6Z" fill="white" />
                            </g>
                          </svg>
                          Download material</a>
                      </>
                    ) : (
                      <>
                        <a href="https://bit.ly/Dia2-SaberImobV" className="button w-button">
                          Assista a palestra</a>
                      </>
                    )}
                  </div>
                  <h5 className="palestrante-bio"><strong>Bio: Marta Faustino</strong></h5>
                  <div className="text-block-2">
                    <div className="div-line-point">
                      Marta Faustino é CEO do DTB Group, que faturou mais de 25 milhões de reais em 2023. Pioneira na integração entre corretores brasileiros e Realtors® nos EUA, ela capacita corretores com 19 anos de experiência no mercado americano. Criadora do Flórida Connection Road Show e palestrante renomada, Marta demonstra que é possível construir uma carreira internacional do zero, alcançando mais de 27 milhões de VGV no primeiro semestre de 2024.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="columns w-row programacao-block">
              <div className="w-col w-col-4">
                <div className="div-block-3">
                  <img src={Palestrante3} loading="lazy" sizes="(max-width: 479px) 90vw, (max-width: 767px) 85vw, 30vw" width="500"
                    alt="Palestrante"></img>
                </div>
              </div>
              <div className="w-col w-col-8">
                <div className="div-block-4">
                  <div className='programacao-big-title'>
                    Dia 3 (20/06)
                  </div>
                  <div className="text-block-15">
                    <img src={Clock} loading="lazy" alt="Clock"></img>
                    19h30 às 21h30
                  </div>
                  <h4 className="heading-3"><strong>Somos um produto! Destaque-se na prateleira do mercado.</strong></h4>
                  <div className="text-block-2">
                    <div className="div-line-point">
                      Raquel Lacerda falará para os corretores de imóveis como um profissional de sucesso se destaca e se posiciona no mercado de imóveis de luxo. Além disso, a importância de se tornar um corretor atrativo e valioso aos olhos dos clientes.
                    </div>
                  </div>
                  <div className='button-container'>
                    {(isDownloadButton) ? (
                      <>
                        <a href="https://bit.ly/SaberV-Resumo-3" className="button w-button">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <mask id="mask0_37_2417" style={{
                              maskType: "alpha"
                            }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                              <rect width="24" height="24" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_37_2417)">
                              <path d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6Z" fill="white" />
                            </g>
                          </svg>
                          Download material</a>
                      </>
                    ) : (
                      <>
                        <a href="https://bit.ly/Dia3-SaberImobV" className="button w-button">
                          Assista a palestra</a>
                      </>
                    )}
                  </div>
                  <h5 className="palestrante-bio"><strong>Bio: Raquel Lacerda</strong></h5>
                  <div className="text-block-2">
                    <div className="div-line-point">
                      Raquel Lacerda, com mais de 15 anos de mercado, é uma corretora e empreendedora de sucesso. Fundadora da imobiliária Sua Casa Digital, mulher e mãe, coleciona diversas vitórias e conquistas no mercado do Rio de Janeiro. Gerente campeã consecutiva por diversas empresas, hoje lidera um time próprio e seleto de corretores de alto padrão.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
        <div className="section-4" >
          <div className="container-7 w-container">
            <div className="w-row" align="center">
              <div className="w-clearfix w-col w-col-5">
                <div>
                  <div className="text-block-6">
                    Realização
                    <img src={LogoCofeci} loading="lazy" width="130" alt="" className="image-3 creci"></img>
                    <img src={LogoSebrae} loading="lazy" width="130" alt="" className="image-3"></img></div>
                </div>
              </div>
              <div className="w-col div-separator"></div>
              <div className="w-col w-col-6">
                <div className="w-clearfix">
                  <div className="text-block-6">Apoio
                    <img src={LogoIGlobal} loading="lazy" width="130" alt="" className="image-3"></img>
                    {/* <img src={LogoIbrep} loading="lazy" width="130" alt="" className="image-3"></img> */}
                    <img src={LogoHomer2} loading="lazy" width="130" alt="" className="image-3"></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-image">

        </div>
        <div className="section-5">
          <div className="w-container">
            <div className="text-block-5">Powered by Homer</div>
          </div>
        </div>
      </body >
    </>
  )
};