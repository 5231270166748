import HttpClient from './utils/HttpClient';

class StatesService {
  constructor(baseUrl) {
    
    this.baseUrl = baseUrl;

    this.httpClient = new HttpClient(this.baseUrl)
  }

  async getAll() {
    const urlPath = '/states/get-all';

    return this.httpClient.get(urlPath);
  }
}

export default StatesService;