import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  /* padding-bottom: 16px; */
  
  &.complex{
    /* display: flex;
    align-items: center;
    justify-content: space-between; */

    .group{
      input{
        /* max-width: 250px; */
        max-width: 90%;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    
    button{
      background: transparent;
      border: none;
      margin-left: 8px;
      cursor: pointer;
    }
  }

  small{
    margin-top: 8px;
    font-size: 12px;
    display: block;
    color: #fc5050;
  }
  
  & + &{
    margin-top: 16px;
  }
`;