import HttpClient from './utils/HttpClient';

class CitiesService {
  constructor(baseUrl) {

    this.baseUrl = baseUrl;

    this.httpClient = new HttpClient(this.baseUrl)
  }

  async getAll() {
    const urlPath = '/cities/get-all';

    return this.httpClient.get(urlPath);
  }

  async getByStateId(stateId) {
    const stateIdToSend = stateId ? stateId : 'null';
    const urlPath = `/cities/get-all/states/${stateIdToSend}`;

    return this.httpClient.get(urlPath);
  }
}

export default CitiesService;