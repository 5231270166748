import { useState } from 'react';
import { Container } from './styles';
import useErrors from '../../hooks/useErrors';
import { Form, ButtonContainer } from './styles';
import FormGroup from '../../components/FormGroup';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Config from '../../config/environments/local';
import UsersService from '../../services/UsersService';
import Loader from '../../components/Loader';

export default function ForgotPassword() {

  const [email, setEmail] = useState('');
  const isFormValid = (email);
  const [isLoading, setIsLoading] = useState(false);
  const [isResetPaswordSuccess, setIsResetPaswordSuccess] = useState(false);
  const [serviceError, setServiceError] = useState('');

  const {
    // errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  function handleChangeEmail(e) {
    setEmail(e.target.value);

    if (!e.target.value) {
      setError({ field: 'email', message: 'o e-mail é obrigatório' });
    } else {
      removeError('email');
    }
  }

  async function handleGoToLogin(e) {
    e.preventDefault();
    e.stopPropagation();

    window.open('/login', '_self');
  }

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);

    const response = await new UsersService(Config.API_BASE_URL).ResetPassword({
      Email: email,
    });

    if (response.Error) {
      setError({ field: 'email', message: response.Error.Message });
      setServiceError(response.Error.Message);
      setIsResetPaswordSuccess(true);
    } else {
      setIsResetPaswordSuccess(true);
      removeError('email');
    }

    setIsLoading(false);

  }

  return (
    <>
      <Container>
        <div className='login-form-container'>
          <div className='header'>
            <strong>
              Recuperação de senha
            </strong>
          </div>
          {(!isResetPaswordSuccess) ? (
            <Form onSubmit={handleSubmit} noValidate>
              <div>Informe seu e-mail cadastrado</div>
              <FormGroup
                error={getErrorMessageByFieldName('email')}
              >
                <Input
                  type="text"
                  value={email}
                  placeholder="E-mail"
                  onChange={handleChangeEmail}
                  error={getErrorMessageByFieldName('email')}
                />
              </FormGroup>

              <Loader isLoading={isLoading} />

              <ButtonContainer>
                <Button
                  type="button"
                  disabled={!isFormValid}
                  onClick={(e) => handleSubmit(e)}

                >
                  Confirmar
                </Button>
              </ButtonContainer>
            </Form >
          ) : (
            <Container>
              <h4>
                {(serviceError) ? (
                  <>
                    {serviceError}
                  </>
                ) : (
                  <>
                    Enviamos sua senha para [{email}] <br />
                    Acesse a sua caixa de entrada e recupere sua senha
                  </>
                )}
              </h4>

              {(serviceError) ? (
                <ButtonContainer>
                  <Button
                    type="button"
                    onClick={(e) => setIsResetPaswordSuccess(false)}
                  >
                    Alterar o e-mail e tentar novamente
                  </Button>
                </ButtonContainer>
              ) : (
                <ButtonContainer>
                  <Button
                    type="button"
                    onClick={(e) => handleGoToLogin(e)}
                  >
                    Voltar para login
                  </Button>
                </ButtonContainer>
              )}
            </Container>
          )}

        </div>
      </Container>
    </>
  )
};