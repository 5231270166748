import LogoCofeci from '../../assets/images/landing-page/logo_cofeci.png'
import LogoSebrae from '../../assets/images/landing-page/logo_sebrae2x.png'

import LogoHomer2 from '../../assets/images/landing-page/logo_homer2x.png'
// import LogoIbrep from '../../assets/images/landing-page/logoIbrep.png'
import LogoIGlobal from '../../assets/images/landing-page/logo_iglobal2x.png'
import Config from '../../config/environments/local';
import {
  localGet,
  // localRemove
} from "../../lib/session";

// import DepoimentoCircle from '../../assets/images/2024/circle_frame_depoimento.svg'
// import Sabrina from '../../assets/images/landing-page/palestrantes/sabina@2x.png'
// import Kepler from '../../assets/images/landing-page/palestrantes/joao_kepler@2x.png'
// import Walter from '../../assets/images/landing-page/palestrantes/walter@2x.png'

import VideoPlayerIcon from '../../assets/icons/player-icon.svg'
import AspasAmarela from '../../assets/icons/aspas-amarela.svg'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import LeadsService from '../../services/LeadsService'

import {
  useState,
  useEffect,
} from "react";

export default function Home() {
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [userIsLogedIn, setUserIsLogedIn] = useState(false);
  const [images, setImages] = useState({});
  // const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function loadImages() {
      try {
        // setIsLoading(true);
        const imagesList = await new LeadsService(Config.API_BASE_URL).GetImages();
        setImages(imagesList.Result);
      } catch (error) {
        console.log('error ', error);
      } finally {
        // setIsLoading(false);
      }
    }

    loadImages();
  }, [setImages]);

  if (isFirstLoad && sessionObject) {
    setIsFirstLoad(false);
    setUserIsLogedIn(true);
  }

  return (
    <>

      <body className="body">
        {(userIsLogedIn) && (
          <>
            <div className='logged-in-div'>

            </div>
          </>
        )}
        {(!userIsLogedIn) && (
          <>
            <div className="principal">
              <div className="principal sobre-o-evento">
                <div className="w-container">
                  <div className="w-row">
                    <div className="w-col">
                      <div className="div-block-2">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="sobre" className='sobre-o-evento-container'>
              <div className='row'>
                <div className='col-md-7'>
                  <div className='very-big-purple-text'>
                    Lorem ipsum dolor sit amet O que é
                  </div>
                  <div className='smaller-text'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse lacinia ligula quis elementum elementum. Mauris egestas lacus lorem, ut <b>vestibulum turpis</b> fringilla sed. Sed pharetra rhoncus nunc at mollis. Vestibulum vel lacus neque.
                  </div>
                </div>
                <div className='col-md-5'>
                  <div className='gray-square'>

                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-3'>
                  <div className='container-divider'>
                    <div className='title-number-text'>
                      5
                    </div>
                    <div className='subtitle-number-text'>
                      edições nacionais
                    </div>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='container-divider left'>
                    <div className='title-number-text'>
                      24
                    </div>
                    <div className='subtitle-number-text'>
                      CRECIs apoiadores
                    </div>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='container-divider left'>
                    <div className='title-number-text'>
                      196 mil
                    </div>
                    <div className='subtitle-number-text'>
                      visualizações
                    </div>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='container-divider left'>
                    <div className='title-number-text'>
                      40 mil
                    </div>
                    <div className='subtitle-number-text'>
                      inscritos
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='aspas-amarela'>
                    <img src={AspasAmarela} loading="lazy" alt="" />
                  </div>
                  <div className='very-big-purple-text2'>
                    Excelente palestra, prabéns Cofeci, Sebrae e Homer, espero participar por muitos e muitos anos deste encontro, obrigado e grande abaço a todos.
                    <br />
                    <br />
                    <b>Geraldo Matos</b>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='gray-rectangle'>
                    <div className='video-player-icon'>
                      <img src={VideoPlayerIcon} loading="lazy" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='sobre-o-evento-gallery-container'>
              <div className='row d-flex justify-content-center'>
                <div className='col-lg-2 col-md-6'>
                  <div className='gallery-item'>
                    <Zoom>
                      <img src={images.FileUrl1} alt="" />
                    </Zoom>
                  </div>
                </div>
                <div className='col-lg-2 col-md-6'>
                  <div className='gallery-item'>
                    <Zoom>
                      <img src={images.FileUrl2} alt="" />
                    </Zoom>
                  </div>
                </div>
                <div className='col-lg-2 col-md-6'>
                  <div className='gallery-item'>
                    <Zoom>
                      <img src={images.FileUrl3} alt="" />
                    </Zoom>
                  </div>
                </div>
                <div className='col-lg-2 col-md-6'>
                  <div className='gallery-item'>
                    <Zoom>
                      <img src={images.FileUrl4} alt="" />
                    </Zoom>
                  </div>
                </div>
                <div className='col-lg-2 col-md-6'>
                  <div className='gallery-item'>
                    <Zoom>
                      <img src={images.FileUrl5} alt="" />
                    </Zoom>
                  </div>
                </div>
              </div>
              <div className='row d-flex justify-content-center'>
                <div className='col-lg-2 col-md-6'>
                  <div className='gallery-item'>
                    <Zoom>
                      <img src={images.FileUrl6} alt="" />
                    </Zoom>
                  </div>
                </div>
                <div className='col-lg-2 col-md-6'>
                  <div className='gallery-item'>
                    <Zoom>
                      <img src={images.FileUrl7} alt="" />
                    </Zoom>
                  </div>
                </div>
                <div className='col-lg-2 col-md-6'>
                  <div className='gallery-item'>
                    <Zoom>
                      <img src={images.FileUrl8} alt="" />
                    </Zoom>
                  </div>
                </div>
                <div className='col-lg-2 col-md-6'>
                  <div className='gallery-item'>
                    <Zoom>
                      <img src={images.FileUrl9} alt="" />
                    </Zoom>
                  </div>
                </div>
                <div className='col-lg-2 col-md-6'>
                  <div className='gallery-item'>
                    <Zoom>
                      <img src={images.FileUrl10} alt="" />
                    </Zoom>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="section-4" >
          <div className="container-7 w-container">
            <div className="w-row" align="center">
              <div className="w-clearfix w-col w-col-5">
                <div>
                  <div className="text-block-6">
                    Realização
                    <img src={LogoCofeci} loading="lazy" width="130" alt="" className="image-3 creci"></img>
                    <img src={LogoSebrae} loading="lazy" width="130" alt="" className="image-3"></img></div>
                </div>
              </div>
              <div className="w-col div-separator"></div>
              <div className="w-col w-col-6">
                <div className="w-clearfix">
                  <div className="text-block-6">Apoio
                    <img src={LogoIGlobal} loading="lazy" width="130" alt="" className="image-3"></img>
                    {/* <img src={LogoIbrep} loading="lazy" width="130" alt="" className="image-3"></img> */}
                    <img src={LogoHomer2} loading="lazy" width="130" alt="" className="image-3"></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-image">

        </div>
        <div className="section-5">
          <div className="w-container">
            <div className="text-block-5">Powered by Homer</div>
          </div>
        </div>
        {/* <div id="myModal" className="modal">
          <div className="modal-content">
            <span className="close">&times;</span>
            <div className="text-block-7">Insira seu e-mail e senha:</div>
            <div className="w-form">
              <form id="login-form" action="#" method="POST" name="email-form">
                <label for="Email" className="field-label">E-mail</label>
                <input type="email" className="text-field-2 w-input" maxlength="256" name="login-email" id="login-email" placeholder="Insira seu e-mail" required=""></input>
                <label for="Password" className="field-label-2">Senha</label>
                <input type="password" className="text-field w-input" maxlength="256" name="login-pass" id="login-pass" placeholder="Insira sua senha" required=""></input>
                <a href="recuperar-senha.php">Esqueceu sua senha?</a>
                <br />
                <button className="submit-button" id="login-button" name="login-button">ENTRAR</button>
              </form>
            </div>
          </div>
        </div> */}
      </body >
    </>
  )
};