import PropTypes from 'prop-types';
import ReactDOM from "react-dom";
import { Overlay, Container, Footer } from './styles';
import Button from '../Button';

export default function Modal({ title, body, danger, onConfirm, onCancel, isVisible }) {

  function handleCancel() {
    onCancel();
  }

  if (!isVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <Overlay>
        <Container
          danger={danger}
          className={`${danger ? "red" : ""}`}
        >
          <h1>
            {title}
          </h1>
          <p>
            {body}
          </p>

          <Footer>
            {onCancel && (
              <Button
                type='button'
                className='cancel-button'
                onClick={handleCancel}
              >
                Cancelar
              </Button>
            )}
            <Button
              type='button'
              danger={danger}
              className={`${danger ? "red" : ""}`}
              onClick={onConfirm}
            >
              {(danger ? (
                <>
                  Fechar
                </>
              ) : (
                <>
                  Fechar
                </>
              ))}
            </Button>
          </Footer>

        </Container>
      </Overlay>
    </>,
    document.getElementById('modal-root')
  );
}

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  danger: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
}

Modal.defaultProps = {
  danger: false,
  isVisible: false,
  onConfirm: null,
  onCancel: null
}